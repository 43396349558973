// core imports
import { TabbedForm, FormTab } from 'react-admin';
import { useLocation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

// component
import { Edit } from 'components/edit/Edit';

// local imports
import { createShowPath } from 'utils/helper';
import { MerchantForm } from './MerchantForm';
import { MerchantOutlets } from './MerchantOutlets';
import { MerchantTitle } from './MerchantTitle';

export const MerchantEdit = () => {
  const location = useLocation();
  const path = createShowPath(location.pathname);
  const showConfig = { path, label: 'Show', icon: <VisibilityIcon /> };

  return (
    <Edit
      title={<MerchantTitle />}
      showConfig={showConfig}
      resourceName="Merchant"
    >
      <TabbedForm>
        <FormTab label="summary">
          <MerchantForm isEdit />
        </FormTab>
        <FormTab label="Outlets" path="outlets">
          <MerchantOutlets isEdit />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
