// core import
import { useRecordContext, useGetOne } from 'react-admin';

/**
 * AddressTitle Component
 * @returns <AddressTitle> ReactNode
 */
export const AddressTitle = ({ ...props }) => {
  const record = useRecordContext();
  const { data: outlet } = useGetOne('outlets', { id: record?.id });

  return !record ? null : (
    <span>
      {props.title} {outlet?.outletId} - {outlet?.name}
    </span>
  );
};
