import { ReactNode } from 'react';
import {
  Edit as RaEdit,
  useNotify,
  useRedirect,
  useResourceContext,
} from 'react-admin';
import { transform, getErrorMessage } from 'utils';
import { ShowActions } from 'components/show-action/ShowActions';
import { Error } from 'resources/types/resources.types';

type EditType = {
  children: ReactNode;
  showConfig?: {
    path: string;
    label: string;
    icon: JSX.Element;
  };
  [key: string]: unknown;
};

export const Edit = ({
  children,
  showConfig,
  resourceName,
  ...rest
}: EditType) => {
  const redirectToResource = useResourceContext();
  const notify = useNotify();
  const redirect = useRedirect();

  const redirectTo = 'list';

  const onError = (error: Error | unknown) => {
    if (!error) {
      return;
    }

    const errorMsg = getErrorMessage(error);

    if (errorMsg) {
      notify(errorMsg as string, { type: 'error' });
    }
  };

  const onSuccess = () => {
    notify(` ${resourceName} successfully updated!`, {
      type: 'success',
    });
    if (redirectTo === 'list') {
      redirect('list', `${redirectToResource}`);
    } else {
      redirect(redirectTo);
    }
  };

  return (
    <RaEdit
      actions={
        showConfig ? (
          <ShowActions
            path={showConfig.path}
            label={showConfig.label}
            icon={showConfig.icon}
          />
        ) : undefined
      }
      mutationOptions={{ onError, onSuccess }}
      mutationMode="pessimistic"
      transform={transform}
      {...rest}
    >
      {children}
    </RaEdit>
  );
};

Edit.defaultProps = {
  showConfig: undefined,
};
