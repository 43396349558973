// core imports
import { useState, useEffect, useCallback } from 'react';
import {
  Datagrid as RaDatagrid,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useDataProvider,
  FormDataConsumer,
  useRecordContext,
  BulkDeleteButton,
} from 'react-admin';

// material-ui
import { Box } from '@mui/material';

// router
import { useSearchParams } from 'react-router-dom';

// local components
import { Datagrid } from 'components/data-grid/DataGrid';
import { EditButton } from 'components/buttons/EditButton';
import {
  FromFunctionField,
  UntilFunctionField,
} from 'resources/opening-hour/OpeningHoursList';

import OpeningHoursAdd from 'resources/opening-hour/OpeningHoursAdd';
import { WeekdayField } from '../../components/weekday-field/WeekdayField';
import OfferedServiceCreateButton from '../offered-service/OfferedServiceCreateButton';

// types
import {
  AvailableServiceType,
  OutletService,
  ServiceType,
  EditWithRedirection,
} from '../types/resources.types';

const OpeningHoursEditButton = ({
  recordContext = {},
  outletId,
  merchantId,
}: EditWithRedirection) => {
  const record = useRecordContext(recordContext);

  if (!record) {
    return null;
  }

  let editRedirect = `/opening-hours/${record?.id}?outlet_id=${outletId}`;

  if (merchantId) {
    editRedirect = `${editRedirect}&merchant_id=${merchantId}`;
  }

  return <EditButton navigateTo={editRedirect} />;
};

export const OutletServices = ({
  selectedOutletId,
  allServices,
}: OutletService) => {
  const dataProvider = useDataProvider();

  const [availableServices, setAvailableServices] = useState<
    AvailableServiceType[]
  >([]);
  const [pendingServices, setPendingService] = useState<
    ServiceType[] | undefined
  >([]);

  const [searchParams] = useSearchParams();
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  const getServicesByOutlet = useCallback(() => {
    dataProvider
      .getList('offered-services', {
        pagination: { page: 1, perPage: 20 },
        sort: { field: 'id', order: 'ASC' },
        filter: { outletId: selectedOutletId },
      })
      .then(({ data }) => {
        const offeredServicesByOutlet = data.map((offeredService) => ({
          ...offeredService,
          name: allServices?.find(
            (service: ServiceType) => service?.id === offeredService?.serviceId
          )?.name,
        }));

        const pendingServicesToBeOffered = allServices?.filter(
          (service: ServiceType) =>
            !data.some(
              (offeredService) => service.id === offeredService.serviceId
            )
        );
        setPendingService(pendingServicesToBeOffered);
        setAvailableServices(offeredServicesByOutlet);
      });
  }, [allServices, dataProvider, selectedOutletId]);

  const onServiceCreation = (id: number) => {
    if (id) {
      getServicesByOutlet();
    }
  };

  useEffect(() => {
    if (selectedOutletId) {
      getServicesByOutlet();
    }
  }, [selectedOutletId, dataProvider, allServices, getServicesByOutlet]);

  return (
    <FormDataConsumer>
      {() => (
        <>
          <ReferenceManyField
            reference="offered-services"
            label="Offered Services"
            target="outletId"
          >
            {availableServices.length > 0 && (
              <Box sx={{ alignSelf: 'flex-end' }}>
                <OpeningHoursAdd
                  selectedMerchantId={selectedMerchantId}
                  selectedOutletId={selectedOutletId}
                />
              </Box>
            )}

            <Box sx={{ width: '100%' }}>
              <Datagrid empty={<>No offered services found</>}>
                <ReferenceField
                  source="serviceId"
                  reference="services"
                  label="Offered-Services"
                  link={false}
                >
                  <TextField source="name" />
                </ReferenceField>

                <ReferenceManyField
                  reference="opening-hours"
                  label="Opening Hours"
                  target="offeredServiceId"
                >
                  <RaDatagrid
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    bulkActionButtons={
                      <BulkDeleteButton
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    }
                    empty={<>No opening hours found</>}
                  >
                    <WeekdayField source="weekDay" />
                    <FromFunctionField reference="services" source="from" />
                    <UntilFunctionField source="until" />
                    <OpeningHoursEditButton
                      outletId={selectedOutletId}
                      merchantId={selectedMerchantId}
                    />
                  </RaDatagrid>
                </ReferenceManyField>
              </Datagrid>
            </Box>
          </ReferenceManyField>
          {allServices &&
            allServices?.length &&
            availableServices.length < allServices?.length && (
              <Box sx={{ marginTop: 5 }}>
                <OfferedServiceCreateButton
                  services={pendingServices}
                  outletId={selectedOutletId}
                  onChange={onServiceCreation}
                />
              </Box>
            )}
        </>
      )}
    </FormDataConsumer>
  );
};
