// core imports
import { ReactElement } from 'react';
import {
  Create as RaCreate,
  useNotify,
  useRedirect,
  useResourceContext,
} from 'react-admin';

// components
import { getErrorMessage, transform } from 'utils';

type CreateType = {
  children: ReactElement;
  [key: string]: unknown;
};

export const Create = ({ children, resourceName, ...rest }: CreateType) => {
  const redirectToResource = useResourceContext();
  const notify = useNotify();
  const redirect = useRedirect();

  const redirectTo = 'list';

  const onError = (error: Error | unknown) => {
    if (!error) {
      return;
    }

    const errorMsg = getErrorMessage(error);

    if (errorMsg) {
      notify(errorMsg as string, { type: 'error' });
    }
  };

  const onSuccess = () => {
    notify(`${resourceName} successfully created!`, { type: 'success' });

    if (redirectTo === 'list') {
      redirect('list', `${redirectToResource}`);
    } else {
      redirect(redirectTo);
    }
  };

  return (
    <RaCreate
      mutationOptions={{ onError, onSuccess }}
      transform={transform}
      redirect="list"
      {...rest}
    >
      {children}
    </RaCreate>
  );
};
