import { Button } from 'react-admin';

import { styled } from '@mui/system';

export const WrapperComponent = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

export const RedirectButton = styled(Button)({
  alignSelf: 'flex-start',
});
