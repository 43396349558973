import { TextInput } from 'react-admin';
import { Box } from '@mui/material';
import { emailValidator, requiredValidator } from 'utils/validators';

export const ContactStep = () => (
  <Box flex="1" mt={-1} data-testid="contact-step">
    <Box display="flex">
      <TextInput source="fax" fullWidth />
    </Box>
    <Box display="flex">
      <TextInput source="phone" fullWidth />
    </Box>
    <Box display="flex">
      <TextInput
        source="email"
        fullWidth
        validate={[requiredValidator, emailValidator]}
      />
    </Box>
  </Box>
);
