import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Admin } from 'Admin';
import { theme } from './styles/theme';

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Admin />
  </ThemeProvider>
);

export default App;
