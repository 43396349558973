import { ChangeEvent, useEffect, useState } from 'react';
import { Input, LinearProgress, List, ListItemButton } from '@mui/material';

import { DataProvider } from 'dataProvider/DataProvider';
import { LoqateAddress, LoqateItem } from '../../resources/types/loqate';
import { useDebounce } from '../../hooks/useDebounce';

const MIN_CHARS_TO_SEARCH = 2;
const HINT_MESSAGE_MIN_CHARS = `Enter at least ${MIN_CHARS_TO_SEARCH} characters`;
const HINT_MESSAGE_NO_RESULTS =
  'Type in more details to get a complete address';

const STYLES = {
  container: {
    padding: '1rem 0.75rem 0.75rem',
  },
  input: {
    padding: '0.25rem',
  },
};

export const AddressValidator = ({ onSelect }) => {
  const [addressItems, setAddressItems] = useState<LoqateItem[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [hintMessage, setHintMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const getSuggestions = async (text: string): Promise<LoqateItem[]> => {
    try {
      setIsLoading(true);
      const { data }: { data: LoqateItem[] } =
        await DataProvider.findLoqateAddresses(text);
      return data ?? [];
    } catch (error) {
      console.debug('Error fetching address suggestions:', error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const selectSuggestion = async ({ Id, Type }: LoqateItem) => {
    if (Type === 'Address') {
      try {
        setIsLoading(true);
        const { data }: { data: LoqateAddress } =
          await DataProvider.retrieveLoqateAddress(Id);
        if (data) {
          setAddressItems([]);
          onSelect(data);
        }
      } catch (error) {
        console.debug('Error fetching address:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value.length < MIN_CHARS_TO_SEARCH) {
      setHintMessage(HINT_MESSAGE_MIN_CHARS);
    } else {
      setHintMessage('');
    }

    setSearchValue(target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const items = await getSuggestions(debouncedSearchValue);
        setHintMessage(items.length ? '' : HINT_MESSAGE_NO_RESULTS);
        setAddressItems(items);
      } catch (error) {
        console.debug('Error fetching addresses:', error);
      }
    };

    if (debouncedSearchValue.length < MIN_CHARS_TO_SEARCH) {
      setAddressItems([]);
      return;
    }
    fetchData();
  }, [debouncedSearchValue]);

  return (
    <div style={STYLES.container}>
      <Input
        sx={STYLES.input}
        fullWidth
        onChange={handleChange}
        value={searchValue}
        placeholder="Search address"
        inputProps={{ 'data-testid': 'validator-input' }}
      />
      {isLoading && <LinearProgress />}
      {hintMessage && <p style={STYLES.input}>{hintMessage}</p>}
      <List hidden={!addressItems.length}>
        {addressItems.map((item) => (
          <ListItemButton
            key={item.Id}
            onClick={() => selectSuggestion(item)}
            disabled={isLoading}
          >
            {item.Text || item.Description}
          </ListItemButton>
        ))}
      </List>
    </div>
  );
};
