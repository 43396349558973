import { RESOURCES } from 'resources/config/resources.config';
import SupervisorAccountsIcon from '@mui/icons-material/SupervisorAccount';
import { ExpertList } from './ExpertList';
import { ExpertEdit } from './ExpertEdit';
import { ExpertCreate } from './ExpertCreate';
import { ExpertShow } from './ExpertShow';

export default {
  key: RESOURCES.experts,
  name: RESOURCES.experts,
  list: ExpertList,
  edit: ExpertEdit,
  create: ExpertCreate,
  show: ExpertShow,
  icon: SupervisorAccountsIcon,
  options: { label: 'Experts' },
};
