import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  RaRecord,
  SelectInput,
} from 'react-admin';
import { Box } from '@mui/material';

import { MARKET_TYPES } from 'resources/config/resources.config';
import { requiredValidator } from 'utils/validators';

const optionRenderer = (choice: RaRecord) => {
  if (choice?.merchantName) {
    return `${choice?.companyId} - ${choice?.merchantName}`;
  }

  return `${choice?.companyId}`;
};

export const MarketAreaForm = () => (
  <SimpleForm disableInvalidFormNotification>
    <Box display="flex" width={220}>
      <ReferenceInput source="merchantId" reference="merchants" fullWidth>
        <AutocompleteInput
          fullWidth
          optionText={optionRenderer}
          label="Partner"
          isRequired
        />
      </ReferenceInput>
    </Box>
    <Box display="flex">
      <TextInput source="name" fullWidth validate={requiredValidator} />
    </Box>
    <Box display="flex">
      <SelectInput source="market" choices={MARKET_TYPES} required />
    </Box>

    <TextInput
      source="postCodes"
      helperText="Provide multiple postal codes if necessary separated by a comma."
      validate={requiredValidator}
    />
  </SimpleForm>
);

export const marketAreaTransForm = (data?: RaRecord): RaRecord | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    ...data,
    postCodes: Array.isArray(data?.postCodes)
      ? data?.postCodes
      : data?.postCodes?.split(','),
  };
};
