import { ReactNode } from 'react';
import { TextField, DatagridConfigurable, usePermissions } from 'react-admin';

import { AuthorizedRoles } from 'utils/auth.config';
import { BulkActionButtons } from '../bulk-action/BulkActionButtons';

type DatagridType = {
  children: ReactNode;
  [key: string]: unknown;
};

const styles = {
  '.RaDatagrid-tableWrapper': {
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'inline-table',
    /** Styles "overflow" and "maxWidth" for horizontal scrolling */
    overflowX: 'auto',
    maxWidth: {
      xs: '90vw',
      sm: '90vw',
      md: '90vw',
      lg: '100vw',
      xl: '100vw',
    },
  },
  '.RaDatagrid-cell': {
    padding: '6px 16px',
  },

  '& .RaDatagrid-rowCell': {
    padding: '6px 16px',
  },
  '& .RaDatagrid-rowCell:last-child': {
    width: '100%',
  },
};
export const Datagrid = ({ children, ...rest }: DatagridType) => {
  const { permissions } = usePermissions();
  const showBulkActions = permissions !== AuthorizedRoles.READ;

  return (
    <DatagridConfigurable
      sx={styles}
      rowClick="show"
      bulkActionButtons={showBulkActions ? <BulkActionButtons /> : false}
      {...rest}
    >
      <TextField source="id" />
      {children}
    </DatagridConfigurable>
  );
};
