import {
  RaRecord,
  useRecordContext,
  UseRecordContextParams,
} from 'react-admin';

import { weekdays } from '../../resources/config/resources.config';

export const WeekdayField = (props?: UseRecordContextParams<RaRecord>) => {
  const record = useRecordContext(props);

  if (!record) {
    return null;
  }

  return (
    <span>
      {weekdays?.find((element) => element?.id === record?.weekDay)?.name}
    </span>
  );
};
