// core imports
import { useEffect } from 'react';
import {
  Show,
  RaRecord,
  SimpleShowLayout,
  ReferenceField,
  FunctionField,
  useRefresh,
} from 'react-admin';

// config
import { MARKET_TYPES } from 'resources/config/resources.config';

// components
import { TextField } from 'components/text-field/TextField';

// mui import
import { Box } from '@mui/material';

// local imports
import { MarketAreaTitle } from './MarketAreaTitle';

export const MarketAreaShow = () => {
  const refresh = useRefresh();

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <Show title={<MarketAreaTitle />}>
      <Box sx={{ display: 'flex' }}>
        <SimpleShowLayout>
          <ReferenceField
            source="merchantId"
            reference="merchants"
            label="Company Id"
          >
            <TextField source="companyId" />
          </ReferenceField>
          <ReferenceField
            source="merchantId"
            reference="merchants"
            label="Partner Name"
          >
            <TextField source="merchantName" />
          </ReferenceField>
          <TextField source="name" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <FunctionField
            label="Market"
            source="market"
            render={(record: RaRecord) =>
              record?.market
                ? MARKET_TYPES.find((market) => market.id === record.market)
                    ?.name
                : '-'
            }
          />
          <FunctionField
            label="PostCodes"
            style={{ wordBreak: 'break-all' }}
            render={(record?: RaRecord) =>
              record ? `${record?.postCodes?.join(',')}` : null
            }
          />
        </SimpleShowLayout>
      </Box>
    </Show>
  );
};
