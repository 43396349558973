// core import
import { useRecordContext } from 'react-admin';

/**
 * MerchantTitle Component
 * @returns <MerchantTitle> ReactNode
 */
export const MerchantTitle = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  const defaultTitle = `Partner ${record?.companyId}`;
  const title = record.merchantName
    ? `${defaultTitle} - ${record.merchantName}`
    : defaultTitle;

  return <span>{title}</span>;
};
