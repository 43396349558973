// core import
import {
  Show,
  TabbedShowLayout,
  Tab,
  useShowContext,
  DateField,
  SimpleShowLayout,
  FunctionField,
  RaRecord,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

// components
import { TextField } from 'components/text-field/TextField';
import { ShowActions } from 'components/show-action/ShowActions';

// config
import { MARKET_TYPES, notApplicable } from 'resources/config/resources.config';

// local import
import { createEditPath, parseVat } from 'utils/helper';
import { MerchantOutlets } from './MerchantOutlets';
import { MerchantTitle } from './MerchantTitle';

const MerchantShowActions = () => {
  const { record } = useShowContext();
  const location = useLocation();
  const path = createEditPath(location.pathname);

  return !record ? null : (
    <ShowActions isDisabled={!!record?.extCompanyId} path={path} />
  );
};

export const MerchantShow = () => (
  <Show title={<MerchantTitle />} actions={<MerchantShowActions />}>
    <TabbedShowLayout>
      <Tab label="summary">
        <Box sx={{ display: 'flex' }}>
          <SimpleShowLayout>
            <TextField source="companyId" label="Company Id" />
            <TextField source="type" />
            <TextField
              source="extCompanyId"
              label="External Company Id"
              emptyText={notApplicable}
            />
            <TextField source="investorName" label="Investor Name" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <FunctionField
              label="Country"
              source="vat"
              render={(record: RaRecord) => {
                const market = MARKET_TYPES.find(
                  (marketTypes) => marketTypes.id === parseVat(record.vat)
                );
                return market?.name ?? '-';
              }}
            />
            <TextField source="merchantName" label="Partner Name" />
            <TextField source="vat" />
            <DateField source="createdAt" label="Created At" locales="sv-SE" />
            <DateField source="updatedAt" label="Updated At" locales="sv-SE" />
          </SimpleShowLayout>
        </Box>
      </Tab>
      <Tab label="Outlets" path="outlets">
        <MerchantOutlets />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
