// mui-icons
import WarningIcon from '@mui/icons-material/Warning';

// core imports
import { useEditController, useGetOne, SimpleShowLayout } from 'react-admin';

// router import
import { Link, useSearchParams } from 'react-router-dom';

// material-ui
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// component
import { TextField } from 'components/text-field/TextField';

// styles
import { WrapperComponent, RedirectButton } from './Outlet.style';

interface OutletAddressProps {
  resource: string;
  title: string;
  type?: string;
  isShow?: boolean;
}

export const OutletAddress = ({
  resource,
  title,
  type,
  isShow,
}: OutletAddressProps) => {
  const [searchParams] = useSearchParams();
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  const controllerProps = useEditController();
  const isAddress = type === 'address';

  const addressMessage = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {isAddress ? (
        <>
          <WarningIcon sx={{ marginRight: 1 }} /> Please add the{' '}
          {title.toLowerCase()} to complete the outlet creation
        </>
      ) : (
        `No ${title.toLowerCase()} found`
      )}
    </Box>
  );

  const { data, error, isLoading } = useGetOne(
    resource,
    {
      id: controllerProps?.record?.id,
    },
    { retry: false }
  );
  let errorStatus;

  if (error) {
    errorStatus = JSON.parse(JSON.stringify(error))?.status;
  }

  if (errorStatus === 404) {
    if (isShow) return addressMessage;

    let createRedirect = `/${resource}/create?outlet_id=${controllerProps?.record?.id}`;

    if (selectedMerchantId) {
      createRedirect = `${createRedirect}&merchant_id=${selectedMerchantId}`;
    }

    return (
      <>
        {addressMessage}
        <RedirectButton
          sx={{ marginTop: 2 }}
          variant="contained"
          size="medium"
          component={Link}
          to={createRedirect}
          label={`Create ${title}`}
          title={`Create ${title}`}
        >
          <AddIcon />
        </RedirectButton>
      </>
    );
  }

  let editRedirect = `/${resource}/${data?.id}?outlet_id=${data?.id}`;

  if (selectedMerchantId) {
    editRedirect = `${editRedirect}&merchant_id=${selectedMerchantId}`;
  }

  return (
    <WrapperComponent>
      {!isLoading && (
        <Box sx={{ display: 'flex' }}>
          {isAddress ? (
            <>
              <SimpleShowLayout record={data}>
                <TextField source="street" />
                <TextField source="buildingNumber" />
                <TextField source="postalCode" />
                <TextField source="city" />
                <TextField source="countryName" />
              </SimpleShowLayout>
              <SimpleShowLayout record={data}>
                <TextField source="province" />
                <TextField source="provinceCode" />
                <TextField source="latitude" />
                <TextField source="longitude" />
              </SimpleShowLayout>
            </>
          ) : (
            <SimpleShowLayout record={data}>
              <TextField source="city" />
              <TextField source="street" />
              <TextField source="postalCode" />
              <TextField source="countryIso2" />
            </SimpleShowLayout>
          )}
        </Box>
      )}
      {!isLoading && !isShow && (
        <RedirectButton
          variant="contained"
          size="medium"
          component={Link}
          to={editRedirect}
          label={`Edit ${title}`}
          title={`Edit ${title}`}
        >
          <EditIcon />
        </RedirectButton>
      )}
    </WrapperComponent>
  );
};

OutletAddress.defaultProps = {
  isShow: false,
  type: 'address',
};
