import {
  Datagrid,
  DateField,
  ReferenceManyField,
  useEditController,
} from 'react-admin';

import { useSearchParams } from 'react-router-dom';

import { Pagination } from 'components/list/Pagination';
import { TextField } from 'components/text-field/TextField';

import { CreateContractButton } from './CreateContractButton';
import { EditContractButton } from './EditContractButton';

const EmptyMessage = () => <div>No contracts found</div>;

export const OutletContracts = ({ isShow = false }: { isShow?: boolean }) => {
  const controllerProps = useEditController();
  const [searchParams] = useSearchParams();
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  return (
    <>
      <ReferenceManyField
        reference="contracts"
        label="contracts"
        target="outletId"
        pagination={<Pagination />}
      >
        <Datagrid empty={<EmptyMessage />}>
          <TextField source="contractType" label="Contract type" />
          <TextField source="contractName" label="Subject matter" />
          <DateField source="startDate" label="Start date" />
          <DateField source="endDate" label="End date" />
          <TextField source="comment" label="Comment" />
          {!isShow && (
            <EditContractButton
              outletId={controllerProps?.record?.id}
              merchantId={selectedMerchantId}
            />
          )}
        </Datagrid>
      </ReferenceManyField>
      {!isShow && (
        <CreateContractButton
          outletId={controllerProps?.record?.id}
          merchantId={selectedMerchantId}
        />
      )}
    </>
  );
};

OutletContracts.defaultProps = {
  isShow: false,
};
