// core imports
import {
  AppBar as RaAppBar,
  ToggleThemeButton,
  UserMenu as RaUserMenu,
  useLogout,
  useTheme,
  usePermissions,
} from 'react-admin';

// Mui imports
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import {
  styled,
  MenuItem,
  Box,
  Link,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@mui/material';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import PersonIcon from '@mui/icons-material/Person';

// helper
import { readFromStorage } from 'utils/helper';

// config
import { SupportEmail, SupportEmailSubject } from './AppBar.config';

// logo
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as LogoNegative } from '../../assets/images/logo_negative.svg';

const useStyles = makeStyles({
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  logoBox: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
  },
  account: {
    marginLeft: 'auto',
  },
});

const AppFrame = styled('div')(() => ({
  '@media (min-width:0px)': {
    marginTop: 48,
  },
}));

const UserInfoMenuItem = styled(MenuItem)(() => ({
  '&.Mui-disabled': {
    opacity: 1,
  },
}));

/**
 * LogoutButton Component
 *
 * @returns <LogoutButton> ReactNode
 */

const LogoutButton = () => {
  const logout = useLogout();
  const handleClick = () => logout();

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <ExitIcon />
      </ListItemIcon>
      <ListItemText>Logout</ListItemText>
    </MenuItem>
  );
};

/** Get the user information to display in the UserMenu */
export const getUserInfo = () => {
  const accountInfo = readFromStorage('AUTH_ACCOUNT_INFO');

  if (!accountInfo) {
    return null;
  }

  try {
    return JSON.parse(accountInfo).profile;
  } catch (error) {
    console.debug('Failed to parse account info', error);
    return null;
  }
};

/** Format the role name to display in the UserMenu */
export const formatRoleName = (role: string) => {
  switch (role) {
    case 'admin':
      return 'Admin';
    case 'network_manager':
      return 'Network Manager';
    case 'read':
      return 'Read';
    case 'market_admin':
      return 'Market Admin';
    default:
      return 'Unknown Role';
  }
};

/**
 * UserInformation component
 * Display user information in the UserMenu
 *
 * @returns <UserInformation> ReactNode
 */
const UserInformation = () => {
  const userInfo = getUserInfo();
  const { permissions } = usePermissions();

  const userName = `${userInfo?.firstName ?? ''} ${userInfo?.lastName ?? ''}`;
  const userRole = formatRoleName(permissions);

  return (
    <>
      <UserInfoMenuItem disabled>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText
          primary={`${userName} (${userRole})`}
          secondary={userInfo?.email ?? ''}
        />
      </UserInfoMenuItem>
      <Divider />
    </>
  );
};

/**
 * UserMenu Component
 *
 * @returns <UserMenu> ReactNode
 */

const UserMenu = () => (
  <>
    <RaUserMenu>
      <UserInformation />
      <LogoutButton />
    </RaUserMenu>
  </>
);

const AppBar = () => {
  const classes = useStyles();
  const [theme] = useTheme();

  return (
    <AppFrame>
      <RaAppBar userMenu={<UserMenu />} toolbar={<ToggleThemeButton />}>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <Box className={classes.logoBox}>
          {theme === 'light' ? <Logo /> : <LogoNegative />}
        </Box>
        <Box className={classes.account}>
          <Link
            href={`mailto:${SupportEmail}?subject=${SupportEmailSubject}`}
            underline="none"
          >
            Support / Help
          </Link>
        </Box>
      </RaAppBar>
    </AppFrame>
  );
};

export default AppBar;
