// core import
/* eslint-disable no-extra-boolean-cast */
import {
  AUTH_LOGOUT,
  AUTH_GET_PERMISSIONS,
  AUTH_CHECK,
  AUTH_ERROR,
  AuthActionType,
} from 'react-admin';
import axios from 'axios';

import { generateCodeChallenge, generateCodeVerifier } from 'utils/auth';

// authProvider config
import { ENDPOINTS } from '../utils/api-endpoints';
import { AUTH_COOKIES } from '../utils/auth.config';

// helpers
import { readFromStorage, setToStorage, clearStorage } from '../utils/helper';

const getAccessTokenFromStorage = () => {
  const token = readFromStorage(AUTH_COOKIES.authToken);
  if (!token) {
    return '';
  }

  const accessToken = JSON.parse(token)?.access_token;
  return accessToken;
};

/**
 * Redirects the user to the authorize URL
 */
const authorizeRedirect = async () => {
  // Generate code verifier and code challenge
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  // Store the code verifier in session, to be sent for validation upon token creation
  setToStorage('codeVerifier', codeVerifier);

  const params = new URLSearchParams({
    response_type: 'code',
    client_id: process.env.REACT_APP_GIGYA_CLIENT_ID || '',
    code_challenge_method: 'S256',
    scope: 'openid email profile CustScope CustScope1 CustScope2',
    redirect_uri: process.env.REACT_APP_GIGYA_REDIRECT_URI || '',
    code_challenge: codeChallenge,
  });

  const url = `${ENDPOINTS.AUTH.AUTHORIZE}?${params}`;
  window.location.href = url;
};

/**
 * @method isValidToken
 * Checks if token is valid
 *
 * @returns boolean
 */
const isValidToken = async (token: string) => {
  if (!token) {
    return false;
  }

  try {
    const accessToken = getAccessTokenFromStorage();

    if (!accessToken) {
      return false;
    }

    const tokenIntrospectionResponse = await axios.get(
      ` ${ENDPOINTS.AUTH.TOKEN_INTROSPECT}`,
      {
        headers: {
          'X-Api-Key': `Bearer ${accessToken}`,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return tokenIntrospectionResponse?.data?.active;
  } catch (e) {
    return false;
  }
};

/**
 * @method authProvider
 * Handles authentication and authorization logic
 *
 * @param type: AuthActionType
 * @returns Promise<>
 */

// eslint-disable-next-line consistent-return
export const authProvider = async (type: AuthActionType, params: any) => {
  if (type === AUTH_GET_PERMISSIONS) {
    const accountInfo = readFromStorage(AUTH_COOKIES.authAccountInfo);

    if (!accountInfo) {
      return false;
    }

    try {
      const accountRoles =
        JSON.parse(accountInfo)?.groups?.organizations?.[0]?.roles;

      // API call for user roles
      const response = await axios.get(`${ENDPOINTS.AUTH.ROLE}`, {
        params: {
          roles:
            accountRoles && typeof accountRoles === 'object'
              ? accountRoles.join(',')
              : '',
        },
        headers: {
          'X-Api-Key': `Bearer ${getAccessTokenFromStorage()}`,
          Authorization: `Bearer ${getAccessTokenFromStorage()}`,
        },
      });

      if (response && response?.data) {
        return await Promise.resolve(response.data);
      }
    } catch (err) {
      return false;
    }
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      clearStorage();

      return Promise.reject();
    }
    return Promise.resolve();
  }

  // When the user clicks on the logout button, AUTH_LOGOUT is called
  if (type === AUTH_LOGOUT) {
    const token = getAccessTokenFromStorage();

    if (token) {
      // API call for logout
      try {
        await axios.post(`${ENDPOINTS.AUTH.LOGOUT}`, '', {
          headers: {
            'X-Api-Key': `Bearer ${token}`,
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.debug('Error while logging out', error);
      } finally {
        clearStorage();
        await authorizeRedirect();
      }
    }

    await authorizeRedirect();
  }

  // Each time the user navigates to a list, edit, create or show page, react-admin calls AUTH_CHECK.
  // If this method returns a rejected Promise, react-admin calls AUTH_LOGOUT
  if (type === AUTH_CHECK) {
    const token = readFromStorage(AUTH_COOKIES.authToken);
    const authCode = new URLSearchParams(window.location.search).get('code');

    // Check if token exists
    if (!!token) {
      // Validate the token
      if (await isValidToken(token)) {
        return Promise.resolve();
      }

      clearStorage();
      return Promise.reject();
    }

    // check if the url query params have auth code
    if (authCode) {
      // If AUTH_CHECK_IN_PROGRESS is false, then make API call to fetch tokens
      if (readFromStorage(AUTH_COOKIES.authCheckInProgress) !== 'true') {
        // Before API call, set authCheckInProgress to true to avoid duplicate calls
        setToStorage(AUTH_COOKIES.authCheckInProgress, 'true');
        const codeVerifier = readFromStorage('codeVerifier');

        try {
          // API call to fetch the tokens
          const response = await axios.post(ENDPOINTS.AUTH.TOKEN, undefined, {
            params: {
              authCode,
              codeVerifier,
              redirectUri: process.env.REACT_APP_GIGYA_REDIRECT_URI,
            },
          });

          if (
            response &&
            response?.data?.access_token &&
            response?.data?.id_token
          ) {
            // set the tokens in session
            setToStorage(AUTH_COOKIES.authToken, JSON.stringify(response.data));

            // API call to fetch user info
            const userResponse = await axios.get(
              `${ENDPOINTS.AUTH.USER_INFO}`,
              {
                headers: {
                  'X-Api-Key': `Bearer ${response.data?.access_token}`,
                  Authorization: `Bearer ${response.data?.access_token}`,
                },
              }
            );

            if (userResponse && userResponse?.data) {
              // Store the userInfo in session
              setToStorage(
                AUTH_COOKIES.authUserInfo,
                JSON.stringify(userResponse.data)
              );

              // fetch the accountInfo (which gives the roles of loggedIn user)
              const accountResponse = await axios.get(
                `${ENDPOINTS.AUTH.ACCOUNT}`,
                {
                  headers: {
                    'X-Api-Key': `Bearer ${response.data?.access_token}`,
                    Authorization: `Bearer ${response.data?.access_token}`,
                  },
                }
              );

              if (accountResponse && accountResponse?.data) {
                setToStorage(AUTH_COOKIES.authCheckInProgress, 'false');
                // store accountInfo in sessionStorage
                setToStorage(
                  AUTH_COOKIES.authAccountInfo,
                  JSON.stringify(accountResponse.data)
                );
                window.location.href = '/';

                return await Promise.resolve();
              }
            }
          }
        } catch (error) {
          // If any of the API, (either fetchToken or fetchUserInfo fails),
          // clear the storage and display Unauthorized screen.
          clearStorage();
        }
      }
    } else {
      // Redirect to Login screen
      await authorizeRedirect();
    }
  }
};
