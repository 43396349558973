import { TextInput } from 'react-admin';

export const AddressFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <TextInput source="street" label="Street" />,
  <TextInput source="buildingNumber" label="BuildingNumber" />,
  <TextInput source="postalCode" label="PostalCode" />,
  <TextInput source="city" label="City" />,
  <TextInput source="countryName" label="CountryName" />,
  <TextInput source="province" label="Province" />,
  <TextInput source="provinceCode" label="ProvinceCode" />,
  <TextInput source="latitude" label="Latitude" />,
  <TextInput source="longitude" label="Longitude" />,
];
