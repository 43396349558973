import { Typography, Grid, Paper } from '@mui/material';
import { SimpleShowLayout, useRecordContext } from 'react-admin';
import { useEffect, useState } from 'react';

export const McsExpertStatus = ({ dataProvider }) => {
  const expert = useRecordContext();
  const [error, setError] = useState<unknown>(null);

  const [activeStatus, setActiveStatus] = useState({
    crmStatus: '',
    cdcStatus: '',
  });

  const getColor = (status) => {
    switch (status) {
      case 'Active':
        return 'green';
      case 'Inactive':
        return 'red';
      default:
        return 'red';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'Active':
        return 'User is active';

      case 'Inactive':
        return 'User is inactive';

      case 'NotFound':
        return 'User not found';

      case 'UnexpectedStatusCode':
        return 'Unexpected status code';

      default:
        return '';
    }
  };

  useEffect(() => {
    const fetchExpertStatus = async () => {
      try {
        const { data } = await dataProvider.getExpertStatus({
          id: expert.id,
        });

        setActiveStatus({
          cdcStatus: data.cdcExpert,
          crmStatus: data.crmExpert,
        });
      } catch (err) {
        console.debug('Error fetching expert status', err);
        setError(err);
      }
    };
    if (expert && !error) {
      fetchExpertStatus();
    }
  }, [dataProvider, expert, error]);

  return (
    <SimpleShowLayout sx={{ padding: 0, marginTop: 5 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">MCS</Typography>
            {expert.isActive ? (
              <Typography sx={{ color: 'green' }}>User is active</Typography>
            ) : (
              <Typography sx={{ color: 'red' }}>User is inactive</Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">CDC</Typography>
            <Typography
              sx={{
                color: getColor(activeStatus.cdcStatus),
              }}
            >
              {getStatusText(activeStatus.cdcStatus)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">CRM</Typography>
            <Typography
              sx={{
                color: getColor(activeStatus.crmStatus),
              }}
            >
              {getStatusText(activeStatus.crmStatus)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  );
};
