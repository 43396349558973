export const dashboardStyles = {
  container: {
    width: '100%',
    height: '100%',
    padding: '20px',
    marginTop: '50px',
  },
  title: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '28px',
    marginBottom: '15px',
  },
  listContainer: {
    width: '100%',
    maxWidth: 360,
    margin: 'auto',
    borderLeftStyle: 'ridge',
  },
  sectionItem: {
    height: '100%',
  },
  listItemButton: {
    paddingLeft: '5px',
  },
  box: {
    width: '100%',
    height: '50%',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  syncIcon: {
    color: '#e57373',
  },
  errorMessage: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#FFFFFFB3',
  },
};
