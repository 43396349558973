import {
  useEditController,
  ReferenceManyField,
  TextField,
  Button,
  EmailField,
  FunctionField,
  Datagrid,
  ReferenceField,
  RaRecord,
  useRecordContext,
  BooleanField,
} from 'react-admin';

// router imports
import { Link, useSearchParams } from 'react-router-dom';

// Mui icons
import AddIcon from '@mui/icons-material/Add';

// component
import { EditButton } from 'components/buttons/EditButton';
import { Pagination } from 'components/list/Pagination';

// utils
import { formatPhoneNumber } from 'utils/helper';

// types
import { EditWithRedirection } from '../types/resources.types';

const CreateExpert = ({
  outletId,
  merchantId,
}: {
  outletId: number;
  merchantId?: string;
}) => {
  let createRedirect = `/experts/create/?outlet_id=${outletId}`;

  if (merchantId) {
    createRedirect = `${createRedirect}&merchant_id=${merchantId}`;
  }

  return (
    <Button
      variant="contained"
      size="medium"
      component={Link}
      to={createRedirect}
      label="Add Expert"
      title="Add Expert"
      sx={{
        marginTop: 5,
      }}
    >
      <AddIcon />
    </Button>
  );
};

CreateExpert.defaultProps = {
  merchantId: undefined,
};

const ExpertEdit = ({
  recordContext = {},
  outletId,
  merchantId,
}: EditWithRedirection) => {
  const record = useRecordContext(recordContext);

  if (!record) {
    return null;
  }

  let editRedirect = `/experts/${record?.id}/?outlet_id=${outletId}`;

  if (merchantId) {
    editRedirect = `${editRedirect}&merchant_id=${merchantId}`;
  }

  return <EditButton navigateTo={editRedirect} />;
};

const EmptyMessage = () => <div>No experts found</div>;

export const OutletExperts = ({ isShow = false }: { isShow?: boolean }) => {
  const controllerProps = useEditController();
  const [searchParams] = useSearchParams();
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  const createExpert = (
    <CreateExpert
      outletId={controllerProps?.record?.id}
      merchantId={selectedMerchantId}
    />
  );

  return (
    <>
      <ReferenceManyField
        reference="experts"
        label="Experts"
        target="outletId"
        pagination={<Pagination />}
      >
        <Datagrid empty={<EmptyMessage />}>
          <ReferenceField
            source="typeId"
            reference="expert-types"
            label="Expert-type"
            link={false}
          >
            <TextField source="type" />
          </ReferenceField>
          <TextField source="firstName" />
          <TextField source="lastName" />
          <EmailField source="email" />
          <FunctionField
            label="Phone"
            render={(record: RaRecord) =>
              record?.phone ? formatPhoneNumber(record.phone) : '-'
            }
          />
          <ReferenceField
            source="positionId"
            reference="expert-positions"
            link={false}
          >
            <TextField source="position" />
          </ReferenceField>
          <BooleanField source="isActive" label="Is Active" />
          <FunctionField
            label="Languages"
            render={(record: RaRecord) =>
              record?.languages ? `${record?.languages?.join(', ')}` : ''
            }
          />
          {!isShow && (
            <ExpertEdit
              outletId={controllerProps?.record?.id}
              merchantId={selectedMerchantId}
            />
          )}
        </Datagrid>
      </ReferenceManyField>
      {!isShow && createExpert}
    </>
  );
};

OutletExperts.defaultProps = {
  isShow: false,
};
