import { useSearchParams } from 'react-router-dom';

import { Create } from 'components/create/Create';
import { ContractForm } from './ContractForm';

export const ContractCreate = () => {
  const [searchParams] = useSearchParams();
  const selectedOutletId = searchParams.get('outlet_id') as string | undefined;

  return (
    <Create resourceName="Contract">
      <ContractForm selectedOutletId={selectedOutletId} />
    </Create>
  );
};
