import { useState } from 'react';

import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  Form,
  Create,
  AutocompleteInput,
} from 'react-admin';

// icons
import AddIcon from '@mui/icons-material/Add';
import IconCancel from '@mui/icons-material/Cancel';

// ui components
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// local import
import { requiredValidator } from 'utils/validators';
import { ServiceType } from '../types/resources.types';

// styles
import { OfferedServiceButton } from './OfferedService.style';

const OfferedServiceCreateButton = ({
  services,
  outletId,
  onChange,
}: {
  services: ServiceType[] | undefined;
  outletId: number;
  onChange: (id: number) => void;
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { isLoading }] = useCreate('offeredService');
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (
    values: Record<string | number, string | number>
  ) => {
    create(
      'offered-services',
      { data: { ...values, outletId } },
      {
        onSuccess: (data) => {
          setShowDialog(false);
          notify('Offered Service created successfully');
          onChange(data.id);
        },
        onError: () => {
          // failure side effects go here
          notify('Failed to create offered service', {
            type: 'error',
          });
        },
      }
    );
  };

  return (
    <>
      <OfferedServiceButton
        size="medium"
        variant="contained"
        onClick={handleClick}
        label="Create Offered Service"
      >
        <AddIcon />
      </OfferedServiceButton>
      <Create title={<></>}>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={handleCloseClick}
          aria-label="Create Offered Service"
        >
          <DialogTitle>Create Offered Service</DialogTitle>
          <Form
            warnWhenUnsavedChanges
            resource="offeredService"
            onSubmit={handleSubmit}
          >
            <>
              <DialogContent>
                <AutocompleteInput
                  source="serviceId"
                  sx={{ width: 250 }}
                  choices={services || []}
                  optionText="name"
                  validate={requiredValidator}
                />
              </DialogContent>
              <DialogActions>
                <SaveButton />
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={isLoading}
                >
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          </Form>
        </Dialog>
      </Create>
    </>
  );
};
export default OfferedServiceCreateButton;
