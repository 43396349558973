import {
  TextField,
  FunctionField,
  SimpleList,
  UseRecordContextParams,
  RaRecord,
  useRecordContext,
  downloadCSV,
} from 'react-admin';

import jsonExport from 'jsonexport/dist';

import { List } from 'components/list/List';
import { Datagrid } from 'components/data-grid/DataGrid';
import { ListActions } from 'components/list/ListActions';
import { useSmallScreen } from 'utils/helper';

import { formatTime } from './config';

const OpeningHoursExporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, 'outletId', 'outlets').then((outlets) => {
    const data = records.map((outlet) => ({
      ...outlet,
      outletName: outlets[outlet.outletId] ? outlets[outlet.outletId].name : '',
    }));

    return jsonExport(data, (err, csv) => {
      downloadCSV(csv, 'openingHours');
    });
  });
};

export const UntilFunctionField = (
  props: UseRecordContextParams<RaRecord> | undefined
) => {
  const record = useRecordContext(props);
  return (
    <FunctionField
      label="Until"
      render={() => `${formatTime(record?.until)} `}
    />
  );
};

export const FromFunctionField = (
  props: UseRecordContextParams<RaRecord> | undefined
) => {
  const record = useRecordContext(props);
  return (
    <FunctionField label="From" render={() => `${formatTime(record?.from)} `} />
  );
};

export const OpeningHoursList = () => {
  const isSmallScreen = useSmallScreen();
  return (
    <List
      title="Opening Hours"
      exporter={OpeningHoursExporter}
      actions={<ListActions showExport />}
    >
      {isSmallScreen ? (
        <SimpleList
          primaryText={<TextField source="Outlet" sortable={false} />}
          secondaryText={(record) => record.offeredService.outlet.name}
          tertiaryText={(record) => `${record.from} - ${record.until}`}
          linkType="show"
        />
      ) : (
        <Datagrid>
          <TextField
            source="offeredService.service.name"
            sortable={false}
            label="Service"
          />
          <TextField
            source="offeredService.outlet.name"
            sortable={false}
            label="Outlet"
          />
          <TextField source="weekDay" label="Weekday" />
          <FromFunctionField source="from" />
          <UntilFunctionField source="until" />
        </Datagrid>
      )}
    </List>
  );
};
