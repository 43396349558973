// core imports
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  FunctionField,
  RaRecord,
  DateField,
} from 'react-admin';

// mui import
import { Box } from '@mui/material';

// config
import { COMMUNICATION_TYPES } from 'resources/config/resources.config';

// local imports
import { DataProvider } from 'dataProvider/DataProvider';
import { formatPhoneNumber } from 'utils/helper';
import { ExpertTitle } from './ExpertTitle';
import { McsExpertStatus } from './ExpertStatus';

export const ExpertShow = () => (
  <Show title={<ExpertTitle />}>
    <Box sx={{ display: 'flex' }}>
      <SimpleShowLayout>
        <TextField source="email" />
        <FunctionField
          label="Phone"
          render={(record: RaRecord) =>
            record?.phone ? formatPhoneNumber(record.phone) : '-'
          }
        />
        <TextField source="firstName" emptyText="-" />
        <TextField source="lastName" emptyText="-" />
        <ReferenceField
          label="Expert Type"
          reference="expert-types"
          source="typeId"
          link={false}
        >
          <TextField source="type" />
        </ReferenceField>
        <ReferenceField
          label="Expert Position"
          reference="expert-positions"
          source="positionId"
          link={false}
        >
          <TextField source="position" />
        </ReferenceField>
        <McsExpertStatus dataProvider={DataProvider} />
      </SimpleShowLayout>
      <SimpleShowLayout>
        <FunctionField
          label="Language"
          render={(record: RaRecord) =>
            record?.languages ? `${record?.languages?.join(', ')}` : ''
          }
        />
        <ReferenceField
          source="outletId"
          reference="outlets"
          label="Outlet"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="gigyaId" label="Gigya Id" emptyText="-" />
        <FunctionField
          label="Preferred Communication"
          source="prefCommLang"
          render={(record: RaRecord) =>
            record?.prefCommLang
              ? COMMUNICATION_TYPES.find(
                  (language) => language.id === record.prefCommLang
                )?.name || '-'
              : '-'
          }
        />
        <DateField source="createdAt" label="Created At" locales="sv-SE" />
        <DateField source="updatedAt" label="Updated At" locales="sv-SE" />
      </SimpleShowLayout>
    </Box>
  </Show>
);
