import { RESOURCES } from 'resources/config/resources.config';
import { AuthorizedRoles } from 'utils/auth.config';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { GssnIssueList } from './GssnIssueList';

export default {
  key: RESOURCES.gssnIssues,
  name: RESOURCES.gssnIssues,
  list: GssnIssueList,
  icon: SyncProblemIcon,
  options: { label: 'GSSN+ Issues', requiredRoles: [AuthorizedRoles.ADMIN] },
};
