import { RESOURCES } from 'resources/config/resources.config';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { MarketAreaList } from './MarketAreaList';
import { MarketAreaEdit } from './MarketAreaEdit';
import { MarketAreaCreate } from './MarketAreaCreate';
import { MarketAreaShow } from './MarketAreaShow';

export default {
  key: RESOURCES.marketAreas,
  name: RESOURCES.marketAreas,
  list: MarketAreaList,
  edit: MarketAreaEdit,
  create: MarketAreaCreate,
  show: MarketAreaShow,
  icon: ContactMailIcon,
  options: { label: 'Market Areas' },
};
