// core import
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';

// react-admin import
import { List as RaList } from 'react-admin';

// config
import { RECORD_PER_PAGE } from '../../resources/config/resources.config';
import { Pagination } from './Pagination';

type ListType = {
  children: ReactElement;
  [key: string]: unknown;
};

export const List = ({ children, ...rest }: ListType) => (
  <>
    <Helmet>
      <title>MCS {rest.title as string}</title>
    </Helmet>
    <RaList
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={RECORD_PER_PAGE}
      {...rest}
    >
      {children}
    </RaList>
  </>
);
