// core imports
import { useState, useEffect } from 'react';
import {
  SimpleForm,
  SelectInput,
  useDataProvider,
  ReferenceInput,
  useEditContext,
  useChoicesContext,
  useGetList,
  AutocompleteInput,
  RaRecord,
  useNotify,
  useRedirect,
  useUpdate,
} from 'react-admin';

// router import
import { useParams, useSearchParams } from 'react-router-dom';

// mui components
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

// component
import { Edit } from 'components/edit/Edit';
import { Toolbar } from 'components/toolbar/Toolbar';

// local import
import { Alert } from '@mui/material';
import { requiredValidator } from 'utils/validators';
import { OpeningHoursTitle } from './OpeningHoursTitle';

// types
import {
  OpeningHoursType,
  ServiceType,
  OfferedServiceType,
  SelectInputDefaultValueType,
} from '../types/resources.types';

// config
import {
  weekdays,
  maintenanceService,
  DEFAULT_TIMEZONE,
} from '../config/resources.config';
import {
  ERROR_MESSAGE,
  getEuropeTimeZones,
  validateOpeningHours,
} from './config';

const europeTzList = getEuropeTimeZones();

const timezoneOptionRenderer = (choice) => `${choice.name} (${choice.id})`;

const OpeningHoursForm = ({
  outletId,
  selectedId,
  from,
  until,
  setFrom,
  setUntil,
  validateTimeOnBlur,
}: OpeningHoursType) => {
  const { record } = useEditContext();

  const selectedTimeZoneOffset = record?.from.substring(
    record?.from.indexOf('+')
  );

  const { data: services } = useGetList('services');

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const dataProvider = useDataProvider();

  const maintenanceServiceType: ServiceType[] | undefined = services?.filter(
    (service) => service?.name.includes(maintenanceService)
  );

  useEffect(() => {
    if (outletId) {
      dataProvider
        .getOne('outlets', {
          id: outletId,
        })
        .then(({ data }) => {
          if (
            data?.extOutletId &&
            maintenanceServiceType?.[0]?.id === selectedId
          ) {
            setIsDisabled(true);
          }
        })
        .catch(() => {
          // @todo display error msg to user
        });
    }
  }, [dataProvider, maintenanceServiceType, outletId, selectedId]);

  useEffect(() => {
    setFrom(
      record?.from.indexOf('+') > -1
        ? record?.from?.substr(0, record?.from.indexOf('+'))
        : record?.from
    );
    setUntil(
      record?.until.indexOf('+') > -1
        ? record?.until?.substr(0, record?.from.indexOf('+'))
        : record?.until
    );
  }, [record?.from, record?.until, setFrom, setUntil]);

  return (
    <>
      <SelectInput
        source="weekDay"
        choices={weekdays}
        validate={requiredValidator}
        disabled={isDisabled}
      />
      <AutocompleteInput
        optionText={timezoneOptionRenderer}
        label="TimeZone"
        source="timeZone"
        optionValue="name"
        defaultValue={
          selectedTimeZoneOffset
            ? europeTzList?.find(
                (timezone) => timezone?.id === selectedTimeZoneOffset
              )?.name
            : DEFAULT_TIMEZONE
        }
        choices={europeTzList}
        sx={{ width: 310 }}
        disabled={isDisabled}
        validate={requiredValidator}
      />

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <TextField
          id="from-time"
          value={from}
          onChange={(e) => {
            setFrom(e.target.value);
          }}
          label="From"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 150 }}
          disabled={isDisabled}
          required
          onBlur={validateTimeOnBlur}
        />
        <TextField
          id="until-time"
          value={until}
          onChange={(e) => {
            setUntil(e?.target?.value);
          }}
          label="Until"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 150 }}
          disabled={isDisabled}
          required
          onBlur={validateTimeOnBlur}
        />
      </Stack>
    </>
  );
};

const SelectInputWithDefaultValue = ({
  selectedId,
  source,
}: SelectInputDefaultValueType) => {
  const { allChoices } = useChoicesContext();
  if (selectedId && allChoices?.length > 0) {
    return (
      <SelectInput
        validate={requiredValidator}
        source={source}
        choices={allChoices}
        optionText="name"
        defaultValue={selectedId}
        disabled
      />
    );
  }

  return null;
};

const OutletAndServiceSelections = (props) => {
  const dataProvider = useDataProvider();
  const { record } = useEditContext();
  const [offeredService, setOfferedService] = useState<OfferedServiceType>();

  useEffect(() => {
    if (record?.offeredServiceId) {
      dataProvider
        .getOne('offered-services', {
          id: record.offeredServiceId,
        })
        .then(({ data }) => {
          setOfferedService(data);
        })
        .catch(() => {
          // @todo display error msg to user
        });
    }
  }, [dataProvider, record?.offeredServiceId]);

  const outletFilters = offeredService?.outletId
    ? { id: offeredService.outletId }
    : {};

  return (
    <>
      <ReferenceInput
        source="Outlet"
        reference="outlets"
        label="Outlet Name"
        filter={outletFilters}
      >
        <SelectInputWithDefaultValue
          selectedId={offeredService?.outletId}
          source="outletId"
        />
      </ReferenceInput>
      <ReferenceInput source="Service" reference="services" label="Service">
        <SelectInputWithDefaultValue
          selectedId={offeredService?.serviceId}
          source="serviceId"
        />
      </ReferenceInput>
      <OpeningHoursForm
        outletId={offeredService?.outletId}
        selectedId={offeredService?.serviceId}
        {...props}
      />
    </>
  );
};

export const OpeningHoursEdit = () => {
  const [searchParams] = useSearchParams();
  const selectedOutletId = searchParams.get('outlet_id') as string | undefined;
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;
  const [from, setFrom] = useState<string>('');
  const [until, setUntil] = useState<string>('');
  const [isInvalidTime, setIsInvalidTime] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [update] = useUpdate('openingHours');
  const notify = useNotify();
  const redirect = useRedirect();
  const params = useParams();

  const transformOpeningHours = (data?: RaRecord) => {
    if (!data) {
      return null;
    }
    const tzOffset = europeTzList.find(
      (timezone) => timezone.name === data?.timeZone
    )?.id;

    return {
      ...data,
      from: from && tzOffset ? `${from}${tzOffset}` : null,
      until: until && tzOffset ? `${until}${tzOffset}` : null,
    };
  };

  let redirectTo = 'list';

  if (selectedOutletId) {
    redirectTo = `/outlets/${selectedOutletId}/services`;

    if (selectedMerchantId) {
      redirectTo = `${redirectTo}?merchant_id=${selectedMerchantId}`;
    }
  }

  const validateTimeOnBlur = () => {
    const invalidTime = validateOpeningHours(from, until);
    if (invalidTime) {
      setIsInvalidTime(true);
      setErrorMessage(ERROR_MESSAGE);
    } else {
      setIsInvalidTime(false);
      setErrorMessage('');
    }
  };

  const saveOpeningHours = (data) => {
    if (isInvalidTime) {
      return;
    }

    const transformedData = transformOpeningHours(data);

    update(
      'opening-hours',
      { id: params?.id, data: { ...transformedData } },
      {
        onSuccess: () => {
          notify('Opening-hour successfully edited', { type: 'success' });

          if (redirectTo === 'list') {
            redirect('list', 'opening-hours');
          } else {
            redirect(redirectTo);
          }
        },
        onError: () => {
          // failure side effects go here
          notify('Failed to update opening hours', {
            type: 'error',
          });
        },
      }
    );
  };

  return (
    <Edit
      title={<OpeningHoursTitle />}
      redirect={redirectTo}
      transform={(data?: RaRecord) => transformOpeningHours(data)}
    >
      <SimpleForm
        toolbar={<Toolbar />}
        onSubmit={saveOpeningHours}
        resource="openingHours"
      >
        <OutletAndServiceSelections
          from={from}
          setFrom={setFrom}
          until={until}
          setUntil={setUntil}
          validateTimeOnBlur={validateTimeOnBlur}
        />
        {errorMessage && isInvalidTime && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
      </SimpleForm>
    </Edit>
  );
};
