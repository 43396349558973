import { RESOURCES } from 'resources/config/resources.config';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { ExpertTypeList } from './ExpertTypeList';
import { ExpertTypeEdit } from './ExpertTypeEdit';
import { ExpertTypeCreate } from './ExpertTypeCreate';

export default {
  key: RESOURCES.expertTypes,
  name: RESOURCES.expertTypes,
  list: ExpertTypeList,
  edit: ExpertTypeEdit,
  create: ExpertTypeCreate,
  icon: EngineeringIcon,
  options: { label: 'Expert Types' },
};
