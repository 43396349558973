// core imports
import { SimpleForm, TextInput, useEditContext } from 'react-admin';

// material ui imports
import { Box } from '@mui/material';

// component
import { Toolbar } from 'components/toolbar/Toolbar';

// local imports
import { emailValidator, requiredValidator } from 'utils/validators';
import { ReferenceOutlet } from '../outlet/ReferenceOutlet';

type ContactFormType = {
  isGssn?: boolean;
  isDisabled?: boolean;
  selectedOutletId?: string;
};

const ContactToolbar = ({
  isGssn = false,
  isDisabled = false,
}: {
  isGssn: boolean;
  isDisabled: boolean;
}) => {
  const { record } = useEditContext();

  if (!isDisabled) {
    return <Toolbar hasDelete={false} isAlwaysEnabled={false} />;
  }

  return !record ? null : <Toolbar isDisabledDelete={isGssn} />;
};

export const ContactForm = ({
  isGssn = false,
  isDisabled = false,
  selectedOutletId,
}: ContactFormType) => {
  let outletFilters = {};

  if (selectedOutletId) {
    outletFilters = { id: selectedOutletId };
  }

  return (
    <SimpleForm
      disableInvalidFormNotification
      toolbar={<ContactToolbar isGssn={isGssn} isDisabled={isDisabled} />}
    >
      <Box flex="1" mt={-1}>
        <Box display="flex" width={120}>
          <ReferenceOutlet
            source="id"
            defaultValue={selectedOutletId}
            filter={outletFilters}
          />
        </Box>
        <Box display="flex">
          <TextInput source="fax" fullWidth />
        </Box>
        <Box display="flex">
          <TextInput source="phone" fullWidth />
        </Box>
        <Box display="flex">
          <TextInput
            source="email"
            fullWidth
            validate={[requiredValidator, emailValidator]}
          />
        </Box>
      </Box>
    </SimpleForm>
  );
};

ContactForm.defaultProps = {
  isGssn: false,
  isDisabled: false,
  selectedOutletId: '',
};
