// core import
import { RaRecord } from 'react-admin';

// component import
import { Create } from 'components/create/Create';

// local import
import { MarketAreaForm, marketAreaTransForm } from './MarketAreaForm';

export const MarketAreaCreate = () => (
  <Create
    transform={(data?: RaRecord) => marketAreaTransForm(data)}
    resourceName="Market-Area"
  >
    <MarketAreaForm />
  </Create>
);
