import { Count, usePermissions } from 'react-admin';

import { Box, Grid, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import StorefrontIcon from '@mui/icons-material/Storefront';

import { AuthorizedRoles } from 'utils/auth.config';
import { LatestUpdatedResource } from './LatestUpdatedResource';
import { GssnIssues } from './GssnIssues';
import { dashboardStyles } from './styles';

export const Dashboard = () => {
  const { container, title, listContainer, sectionItem, listItemButton } =
    dashboardStyles;

  const { permissions } = usePermissions();

  return (
    <Box sx={container}>
      <Grid container spacing={3}>
        {/* Total Outlets Section */}
        <Grid item xs={12} md={6} lg={3} sx={sectionItem}>
          <Typography sx={title}>Number of Outlets</Typography>
          <List sx={listContainer}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <StorefrontIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemButton
                data-testid="total-outlets-btn"
                component={Link}
                to="/outlets"
                sx={listItemButton}
              >
                <ListItemText
                  primary="Total Outlets"
                  secondary={<Count resource="outlets" />}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>

        {/* Total Partners Section */}
        <Grid item xs={12} md={6} lg={3} sx={sectionItem}>
          <Typography sx={title}>Number of Partners</Typography>
          <List sx={listContainer}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ApartmentIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemButton
                data-testid="total-partners-btn"
                component={Link}
                to="/merchants"
                sx={listItemButton}
              >
                <ListItemText
                  primary="Total Partners"
                  secondary={<Count resource="merchants" />}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>

        {/* Total Experts Section */}
        <Grid item xs={12} md={6} lg={3} sx={sectionItem}>
          <Typography sx={title}>Number of Experts</Typography>
          <List sx={listContainer}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemButton
                data-testid="total-experts-btn"
                component={Link}
                to="/experts"
                sx={listItemButton}
              >
                <ListItemText
                  primary="Total Experts"
                  secondary={<Count resource="experts" />}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>

        {/* Latest Updated Resource Section */}
        <Grid item xs={12} md={12} lg={3} sx={sectionItem}>
          <LatestUpdatedResource
            resourceName="outlets"
            primaryTextProp="name"
            secondaryTextProp="outletId"
          />
        </Grid>

        {/* GSSN Issues Section */}
        {permissions === AuthorizedRoles.ADMIN && (
          <Grid item xs={12} md={12} lg={3} sx={sectionItem}>
            <GssnIssues />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
