import { parsePhoneNumberWithError } from 'libphonenumber-js';
import {
  email,
  maxLength,
  minLength,
  regex,
  required,
  Validator,
} from 'react-admin';

export const requiredValidator = required();
export const emailValidator = email();
export const nameLengthValidators = [minLength(3), maxLength(150)];
export const expertNameRegexValidator = (fieldName: string) =>
  regex(
    /^[^-\s¡?÷¿ˆ~!@#$%^&*()_+=[\]{};:",.<>?¢0123456789]{2,20}([' -]{1}[^-\s¡?÷¿ˆ~!@#$%^&*()_+=[\]{};:",.<>?¢0123456789]{1,20}){0,2}$/,
    `Invalid ${fieldName}`
  );

export const phoneValidator: Validator = (phone: string) => {
  try {
    parsePhoneNumberWithError(phone);

    return undefined;
  } catch (error) {
    return {
      message: 'Invalid phone number!!',
      args: {}, // Add an empty args object
    };
  }
};

export const endDateValidator: Validator = (endDate: string, values: any) => {
  if (endDate && !values.startDate) {
    return 'Please provide a start date first';
  }

  if (endDate && endDate <= values.startDate) {
    return 'End date must be after start date';
  }

  return undefined;
};
