import { ReferenceInput, AutocompleteInput, RaRecord } from 'react-admin';

const outletOptionRenderer = (choice: RaRecord) =>
  `${choice?.outletId} - ${choice?.name}`;

const serviceOptionRenderer = (choice: RaRecord) => `${choice?.name}`;

export const OfferedServiceFilters = [
  <ReferenceInput label="Outlet" reference="outlets" source="outletId">
    <AutocompleteInput
      optionText={outletOptionRenderer}
      label="Outlet"
      sx={{ width: 220 }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Service" reference="services" source="serviceId">
    <AutocompleteInput
      optionText={serviceOptionRenderer}
      label="Service"
      sx={{ width: 220 }}
    />
  </ReferenceInput>,
];
