// component import
import { Create } from 'components/create/Create';

// local import
import { ExpertTypeForm } from './ExpertTypeForm';

export const ExpertTypeCreate = () => (
  <Create resourceName="Expert-type">
    <ExpertTypeForm />
  </Create>
);
