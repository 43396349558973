// core imports
import { useState, useEffect } from 'react';
import {
  SimpleForm,
  AutocompleteInput,
  SelectInput,
  SelectArrayInput,
  useDataProvider,
  FormDataConsumer,
  RaRecord,
  useCreate,
  useNotify,
  useRedirect,
} from 'react-admin';

// router import
import { useSearchParams } from 'react-router-dom';

// mui-styles import
import { Alert, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

//  components' import
import { Create } from 'components/create/Create';

import { requiredValidator } from 'utils/validators';

// local component
import { ReferenceOutlet } from '../outlet/ReferenceOutlet';

// config
import {
  DEFAULT_OPENING_END_TIME,
  DEFAULT_OPENING_START_TIME,
  DEFAULT_TIMEZONE,
  weekdays,
} from '../config/resources.config';
import {
  ERROR_MESSAGE,
  getEuropeTimeZones,
  validateOpeningHours,
} from './config';

// types
import { AvailableServiceType, ServiceType } from '../types/resources.types';

type AvalSer = {
  selectedOutletId?: string;
  selectedServiceId?: string;
  allServices?: ServiceType[];
};

const AvailableServices = ({
  selectedOutletId,
  allServices,
  selectedServiceId,
}: AvalSer) => {
  const dataProvider = useDataProvider();
  const [availableServices, setAvailableServices] = useState<
    AvailableServiceType[]
  >([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (selectedOutletId) {
      dataProvider
        .getList('offered-services', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: { outletId: selectedOutletId },
        })
        .then(({ data }) => {
          const offeredServicesByOutlet = data.map((offeredService) => ({
            ...offeredService,
            name: allServices?.find(
              (service: ServiceType) =>
                service?.id === offeredService?.serviceId
            )?.name,
          }));

          setAvailableServices(offeredServicesByOutlet);
          setIsLoaded(true);
        })
        .catch(() => {
          setIsLoaded(true);
        });
    }
  }, [selectedOutletId, dataProvider, allServices]);

  return (
    <SelectInput
      source="offeredServiceId"
      label="Offered Service"
      choices={availableServices}
      optionText="name"
      defaultValue={selectedServiceId ?? ''}
      validate={requiredValidator}
      disabled={!isLoaded || !!selectedServiceId}
      fullWidth
    />
  );
};

AvailableServices.defaultProps = {
  selectedOutletId: '',
  selectedServiceId: '',
  allServices: [],
};

const europeTzList = getEuropeTimeZones();

export const OpeningHoursCreate = () => {
  const dataProvider = useDataProvider();
  const [services, setServices] = useState<ServiceType[] | undefined>([]);
  const [searchParams] = useSearchParams();
  const selectedOutletId = searchParams.get('outlet_id') as string | undefined;
  const selectedServiceId = searchParams.get('service_id') as
    | string
    | undefined;

  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  const [from, setFrom] = useState<string>(DEFAULT_OPENING_START_TIME);
  const [until, setUntil] = useState<string>(DEFAULT_OPENING_END_TIME);
  const [isInvalidTime, setIsInvalidTime] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [create] = useCreate('openingHours');
  const notify = useNotify();
  const redirect = useRedirect();

  useEffect(() => {
    dataProvider
      .getList('services', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      })
      .then(({ data }) => {
        setServices(data);
      })
      .catch(() => {});
  }, [dataProvider]);

  const transformOpeningHours = (data?: RaRecord) => {
    if (!data) {
      return null;
    }
    const tzOffset = europeTzList.find(
      (timezone) => timezone.name === data?.timeZone
    )?.id;

    return data.weekDay.map((wd) => ({
      offeredServiceId: data.offeredServiceId,
      weekDay: wd,
      from: `${from}${tzOffset}`,
      until: `${until}${tzOffset}`,
    }));
  };

  const timezoneOptionRenderer = (choice) => `${choice.name} (${choice.id})`;

  let redirectTo = 'list';
  let outletFilters = {};

  if (selectedOutletId) {
    outletFilters = { id: selectedOutletId };
    redirectTo = `/outlets/${selectedOutletId}/services`;

    if (selectedMerchantId) {
      redirectTo = `${redirectTo}?merchant_id=${selectedMerchantId}`;
    }
  }

  const validateTimeOnBlur = () => {
    const invalidTime = validateOpeningHours(from, until);
    if (invalidTime) {
      setIsInvalidTime(true);
      setErrorMessage(ERROR_MESSAGE);
    } else {
      setIsInvalidTime(false);
      setErrorMessage('');
    }
  };

  const saveOpeningHours = (data) => {
    if (isInvalidTime) {
      return;
    }

    const transformedData = transformOpeningHours(data);

    try {
      transformedData?.forEach((openingHour) => {
        create('opening-hours', { data: { ...openingHour } });
      });

      // Success: Notify and redirect here
      notify('Opening-hour successfully created', { type: 'success' });
      if (redirectTo === 'list') {
        redirect('list', 'opening-hours');
      } else {
        redirect(redirectTo);
      }
    } catch (error) {
      // Error: Handle the error here
      notify('Opening-hours create', {
        type: 'warning',
      });
    }
  };

  return (
    <Create
      redirect={redirectTo}
      transform={(data?: RaRecord) => transformOpeningHours(data)}
      resourceName="Opening-hour"
    >
      <SimpleForm onSubmit={saveOpeningHours} resource="openingHours">
        <Box width={250}>
          <Box display="flex" width={250}>
            <ReferenceOutlet
              defaultValue={selectedOutletId}
              filter={outletFilters}
              isDisabled={!!selectedOutletId}
            />
          </Box>
          <FormDataConsumer>
            {({ formData }) => (
              <AvailableServices
                selectedServiceId={selectedServiceId}
                selectedOutletId={formData.outletId}
                allServices={services}
              />
            )}
          </FormDataConsumer>
        </Box>
        <SelectArrayInput
          label="Weekday"
          source="weekDay"
          choices={weekdays}
          validate={requiredValidator}
          sx={{ width: 250 }}
        />
        <AutocompleteInput
          optionText={timezoneOptionRenderer}
          label="TimeZone"
          source="timeZone"
          optionValue="name"
          defaultValue={DEFAULT_TIMEZONE}
          choices={europeTzList}
          sx={{ width: 310 }}
          validate={requiredValidator}
        />

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <TextField
            id="time"
            value={from}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
            label="From"
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: 150 }}
            required
            onBlur={() => validateTimeOnBlur()}
          />
          <TextField
            id="time"
            value={until}
            onChange={(e) => {
              setUntil(e?.target?.value);
            }}
            label="Until"
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: 150 }}
            required
            onBlur={() => validateTimeOnBlur()}
          />
        </Stack>
        {errorMessage && isInvalidTime && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
      </SimpleForm>
    </Create>
  );
};
