// core import
import { useRecordContext, useGetOne } from 'react-admin';

/**
 * OpeningHoursTitle Component
 * @returns <OpeningHoursTitle> ReactNode
 */
export const OpeningHoursTitle = () => {
  const record = useRecordContext();

  const { data: offeredService } = useGetOne('offered-services', {
    id: record?.offeredServiceId,
  });

  const { data: outlet } = useGetOne('outlets', {
    id: offeredService?.outletId,
  });

  return !record ? null : (
    <span>
      OpeningHours {outlet?.outletId} - {outlet?.name}
    </span>
  );
};
