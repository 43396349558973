import WarningIcon from '@mui/icons-material/Warning';

// core imports
import { useEffect, useState } from 'react';
import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  RaRecord,
  usePermissions,
  useRecordContext,
} from 'react-admin';

// mui imports
import { Box, Typography } from '@mui/material';

import { nameLengthValidators, requiredValidator } from 'utils/validators';
import { AuthorizedRoles } from 'utils/auth.config';

const optionRenderer = (choice: RaRecord) => {
  if (choice?.merchantName) {
    return `${choice?.companyId} - ${choice?.merchantName}`;
  }
  return `${choice?.companyId}`;
};

export const OutletForm = ({
  isDisabled = false,
  isEditable = false,
  selectedMerchantId,
}: {
  isDisabled?: boolean;
  isEditable?: boolean;
  selectedMerchantId?: string;
}) => {
  const { permissions } = usePermissions(); // Get user's permissions
  const isAdmin = permissions === AuthorizedRoles.ADMIN;
  const record = useRecordContext();

  const [isInputDisabled, setIsInputDisabled] = useState(false);

  useEffect(() => {
    const isValid = !!record.vat && !!record.name && !!record.bpId;
    setIsInputDisabled(!isValid);
  }, [record.vat, record.name, record.bpId]);

  return (
    <>
      {isEditable && (
        <Box display="flex">
          <TextInput
            source="outletId"
            label="Outlet Id"
            validate={requiredValidator}
            fullWidth
            disabled
          />
        </Box>
      )}
      <TextInput
        source="name"
        label="Outlet"
        validate={[requiredValidator, ...nameLengthValidators]}
        disabled={isDisabled}
      />
      <Box display="flex" width={220}>
        <ReferenceInput
          emptyText="="
          label="Investor"
          reference="merchants"
          source="merchantId"
          filter={selectedMerchantId ? { id: selectedMerchantId } : {}}
        >
          {selectedMerchantId ? (
            <SelectInput
              label="Partner"
              optionText={optionRenderer}
              defaultValue={selectedMerchantId}
              disabled
            />
          ) : (
            <AutocompleteInput
              validate={requiredValidator}
              label="Partner"
              optionText={optionRenderer}
              disabled={isDisabled}
              fullWidth
            />
          )}
        </ReferenceInput>
      </Box>
      <TextInput source="cofico" format={(cofico) => cofico ?? ''} />
      <TextInput source="brandCode" format={(brandCode) => brandCode ?? ''} />
      <TextInput source="type" disabled format={(type) => type ?? ''} />
      <Box display="flex">
        <BooleanInput source="isMainOutlet" disabled={isDisabled} />
        <BooleanInput source="isApplicant" />
        <Box display="flex">
          <BooleanInput source="isActive" disabled={isInputDisabled} />
          {isInputDisabled && (
            <Box style={{ display: 'flex', marginTop: '0.5em' }}>
              <WarningIcon color="error" />
              <Typography style={{ color: 'red', marginLeft: 5 }}>
                Outlet must have VAT, Name, and bpId to be able to set as
                active.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <TextInput
        source="vat"
        format={(vat) => vat ?? ''}
        disabled={!isAdmin}
        validate={requiredValidator}
      />

      {isEditable && !isDisabled && (
        <TextInput source="bpId" label="Bp Id" disabled sx={{ width: 340 }} />
      )}

      {!isEditable && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <WarningIcon />
          <span>
            Outlet creation is not complete without the address information.
            Please update the Outlet address.
          </span>
        </div>
      )}
    </>
  );
};

OutletForm.defaultProps = {
  isDisabled: false,
  isEditable: false,
  selectedMerchantId: '',
};
