import { DataProvider as RaDataProvider, RaRecord, fetchUtils } from 'ra-core';
import { stringify } from 'querystring-es3';
// config
import { RESOURCES } from 'resources/config/resources.config';
import { formatError, cleanFileName, transformFilter } from 'utils';
import { McsHttpClient } from './helper';

type HeaderType = {
  [key: string]: any;
};

const RADIX = 10;

/**
 * Maps react-admin queries to a REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 */

const CustomDataProvider = (
  mcsHttpClient: typeof fetchUtils.fetchJson,
  countHeader = 'x-total-count'
): RaDataProvider => ({
  // GET
  getList: async (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;
    const filter = transformFilter(params.filter);

    let query: HeaderType = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(filter),
    };

    if (resource === RESOURCES?.outlets) {
      query = { ...query, include: JSON.stringify(['address', 'contact']) };
    }

    const { headers, json } = await mcsHttpClient(
      `/${resource}?${stringify(query)}`
    ).catch((error) => Promise.reject(formatError(error)));
    if (!headers.has(countHeader)) {
      throw new Error(
        `The ${countHeader} header is missing in the HTTP Response. 
          The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination.
          If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
      );
    }

    let resourceList = json;

    /* Modify the list to fetch the extOutletId for addresses and contacts */
    if (resource === RESOURCES?.addresses || resource === RESOURCES?.contacts) {
      const resourceIds = resourceList.map((record: RaRecord) => record?.id);
      const outletQuery = {
        filter: JSON.stringify({ id: resourceIds }),
      };

      /* Fetch the outlets for the selected resourceIds */
      const outletData = await mcsHttpClient(
        `/${RESOURCES?.outlets}?${stringify(outletQuery)}`
      )
        .then(({ json: outletList }) => ({
          outletList,
        }))
        .catch((error) => Promise.reject(formatError(error)));

      /* Modify the records and add externalOutletId and outlet name to the resource list */
      const outlets = outletData?.outletList;
      const modifiedList = resourceList?.map((resourceRecord: RaRecord) => {
        const mappedOutlet = outlets?.find(
          (outlet: RaRecord) => outlet?.id === resourceRecord?.id
        );

        return {
          ...resourceRecord,
          externalOutletId: mappedOutlet?.extOutletId,
          outlet: mappedOutlet?.name,
        };
      });

      resourceList = modifiedList;
    }

    return {
      data: resourceList,
      total: parseInt(headers.get(countHeader.toLowerCase()) as string, RADIX),
    };
  },

  getOne: (resource, params) =>
    mcsHttpClient(`/${resource}/${params.id}`)
      .then(({ json }) => ({
        data: json,
      }))
      .catch((error) => Promise.reject(formatError(error))),

  getExpertStatus: (params) =>
    mcsHttpClient(`/expert/status/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return mcsHttpClient(`/${resource}?${stringify(query)}`)
      .then(({ json }) => ({ data: json }))
      .catch((error) => Promise.reject(formatError(error)));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };

    return mcsHttpClient(`/${resource}?${stringify(query)}`)
      .then(({ headers, json }) => {
        if (!headers.has(countHeader)) {
          throw new Error(
            `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
          );
        }
        return {
          data: json,
          total: parseInt(
            headers.get(countHeader.toLowerCase()) as string,
            RADIX
          ),
        };
      })
      .catch((error) => Promise.reject(formatError(error)));
  },

  findLoqateAddresses: async (text: string) => {
    const { json } = await mcsHttpClient(
      `/loqate/find-addresses?text=${encodeURIComponent(text)}`
    ).catch((error) => Promise.reject(formatError(error)));

    return {
      data: json,
    };
  },

  fetchXlsxFile: async (resource) => {
    const { body, headers } = await mcsHttpClient(`/${resource}/export`, {
      method: 'GET',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    }).catch((error) => Promise.reject(formatError(error)));

    const contentDisposition = headers.get('Content-Disposition');
    let fileName = 'download.xlsx';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      );
      if (fileNameMatch && fileNameMatch.length > 1) {
        fileName = cleanFileName(
          fileNameMatch[1]?.replace(/['"]/g, '') || fileName
        );
      }
    }
    return { body, fileName };
  },

  retrieveLoqateAddress: async (id: string) => {
    const { json } = await mcsHttpClient(
      `/loqate/retrieve-address?id=${encodeURIComponent(id)}`
    ).catch((error) => Promise.reject(formatError(error)));

    return {
      data: json,
    };
  },

  listGssnIssues: async (limit?: number) => {
    const query = limit ? `?limit=${limit}` : '';

    const { json } = await mcsHttpClient(`/outlets/gssn/issues${query}`).catch(
      (error) => Promise.reject(formatError(error))
    );

    return {
      data: json.map((resource) => ({
        ...resource,
        id: resource.pk.S,
        outletName: resource.outletName.S,
        errorMessage: resource.errorMessage.S,
        executionStartTime: resource.executionStartTime.S,
        executionId: resource.executionId.S,
      })),
    };
  },

  // PUT
  update: (resource, params) =>
    mcsHttpClient(`/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => Promise.reject(formatError(error))),

  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        mcsHttpClient(`/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    )
      .then((responses) => ({ data: responses.map(({ json }) => json.id) }))
      .catch((error) => Promise.reject(formatError(error))),

  // POST
  create: (resource, params) =>
    mcsHttpClient(`/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => Promise.reject(formatError(error))),

  // DELETE
  delete: (resource, params) =>
    mcsHttpClient(`/${resource}/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => Promise.reject(formatError(error))),

  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        mcsHttpClient(`/${resource}/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'text/plain',
          }),
        })
      )
    )
      .then((responses) => ({
        data: responses.map(({ json }) => json.id),
      }))
      .catch((error) => Promise.reject(formatError(error))),
});

/**
 * @exports
 * MCS Data Provider
 *
 * @returns DataProvider
 */
export const DataProvider = CustomDataProvider(McsHttpClient);
