// core import
import { Button } from 'react-admin';

// router component import
import { Link } from 'react-router-dom';

// mui icon import
import EditIcon from '@mui/icons-material/Edit';

export const EditButton = ({ navigateTo = '' }: { navigateTo: string }) => (
  <Button
    size="small"
    variant="text"
    component={Link}
    to={navigateTo}
    label="Edit"
    title="Edit"
  >
    <EditIcon />
  </Button>
);
