import { RESOURCES } from 'resources/config/resources.config';
import ArticleIcon from '@mui/icons-material/Article';
import { ContractList } from './ContractList';
import { ContractCreate } from './ContractCreate';
import { ContractEdit } from './ContractEdit';
import { ContractShow } from './ContractShow';

export default {
  key: RESOURCES.contracts,
  name: RESOURCES.contracts,
  list: ContractList,
  create: ContractCreate,
  edit: ContractEdit,
  show: ContractShow,
  icon: ArticleIcon,
  options: { label: 'Contracts' },
};
