import { RESOURCES } from 'resources/config/resources.config';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { OfferedServiceList } from './OfferedServiceList';
import { OfferedServiceCreate } from './OfferedServiceCreate';

export default {
  key: RESOURCES.offeredServices,
  name: RESOURCES.offeredServices,
  list: OfferedServiceList,
  create: OfferedServiceCreate,
  icon: SettingsSuggestIcon,
  options: { label: 'Offered Services' },
};
