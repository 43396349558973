// core import
import { useRecordContext, useGetOne } from 'react-admin';

/**
 * ContactTitle Component
 * @returns <ContactTitle> ReactNode
 */
export const ContactTitle = () => {
  const record = useRecordContext();
  const { data: outlet } = useGetOne('outlets', { id: record?.id });

  return !record ? null : (
    <span>
      Contact {outlet?.outletId} - {outlet?.name}
    </span>
  );
};
