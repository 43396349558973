import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { CircularProgress, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useGetList } from 'react-admin';
import { dashboardStyles } from './styles';

export const LatestUpdatedResource = ({
  resourceName,
  primaryTextProp,
  secondaryTextProp,
}) => {
  const [latestResources, setLatestResources] = useState<any[]>([]);

  const { box, title, spinner, listContainer, listItemButton } =
    dashboardStyles;

  const { data: outlets, isLoading } = useGetList(resourceName, {
    pagination: { page: 1, perPage: 5 },
    sort: { field: 'updatedAt', order: 'DESC' },
    filter: {},
  });

  useEffect(() => {
    if (outlets) {
      setLatestResources(outlets);
    }
  }, [outlets]);

  if (isLoading) {
    return (
      <Box sx={spinner}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={box}>
      <Typography sx={title}>
        5 Latest updated{' '}
        {resourceName.charAt(0).toUpperCase() + resourceName.slice(1)}
      </Typography>
      <List sx={listContainer}>
        {latestResources?.map((resource) => (
          <ListItem key={resource.id}>
            <ListItemAvatar>
              <Avatar>
                <StorefrontIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemButton
              component={Link}
              to={`${resourceName}/${resource.id}/show`}
              sx={listItemButton}
            >
              <ListItemText
                primary={resource[primaryTextProp] || 'Unknown Primary Text'}
                secondary={
                  resource[secondaryTextProp] || 'Unknown Secondary Text'
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
