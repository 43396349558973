import { RESOURCES } from 'resources/config/resources.config';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { AddressList } from './AddressList';
import { AddressEdit } from './AddressEdit';
import { AddressCreate } from './AddressCreate';
import { AddressShow } from './AddressShow';

const address = {
  key: RESOURCES.addresses,
  name: RESOURCES.addresses,
  list: <AddressList title="Addresses" />,
  edit: <AddressEdit resource="addresses" title="Address" />,
  create: <AddressCreate resource="addresses" />,
  show: <AddressShow title="Address" />,
  icon: MapIcon,
};

const deliveryAddress = {
  key: RESOURCES.deliveryAddresses,
  name: RESOURCES.deliveryAddresses,
  list: <AddressList title="Delivery Addresses" type="delivery" />,
  edit: (
    <AddressEdit
      resource="delivery-addresses"
      title="Delivery Address"
      type="delivery"
    />
  ),
  create: <AddressCreate resource="delivery-addresses" type="delivery" />,
  show: <AddressShow title="Delivery Address" type="delivery" />,
  icon: LocalShippingIcon,
  options: { label: 'Delivery Addresses' },
};

export default {
  address,
  deliveryAddress,
};
