// core import
import { TopToolbar, EditButton, usePermissions } from 'react-admin';
import CreateIcon from '@mui/icons-material/Create';
import { AuthorizedRoles } from 'utils/auth.config';

export const ShowActions = ({
  isDisabled = false,
  label = 'Edit',
  path = '',
  icon = <CreateIcon />,
}: {
  isDisabled?: boolean;
  path?: string;
  label?: string;
  icon?: JSX.Element;
}) => {
  const { permissions } = usePermissions();
  const showEdit = permissions !== AuthorizedRoles.READ;

  return (
    <TopToolbar>
      {showEdit && (
        <EditButton disabled={isDisabled} label={label} to={path} icon={icon} />
      )}
    </TopToolbar>
  );
};

ShowActions.defaultProps = {
  isDisabled: false,
  path: '',
  label: 'Edit',
  icon: <CreateIcon />,
};
