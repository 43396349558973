import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';

export const CreateContractButton = ({
  outletId,
  merchantId,
}: {
  outletId: number;
  merchantId?: string;
}) => {
  let createRedirect = `/contracts/create/?outlet_id=${outletId}`;

  if (merchantId) {
    createRedirect = `${createRedirect}&merchant_id=${merchantId}`;
  }

  return (
    <Button
      variant="contained"
      size="medium"
      component={Link}
      to={createRedirect}
      label="Add Contract"
      title="Add Contract"
      sx={{
        marginTop: 5,
      }}
    >
      <AddIcon />
    </Button>
  );
};

CreateContractButton.defaultProps = {
  merchantId: undefined,
};
