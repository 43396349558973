import { TabbedForm, TextInput, FormTab, NumberInput } from 'react-admin';
import { Box } from '@mui/material';

import { mapAddressDefaultValues } from 'utils/helper';
import { requiredValidator } from 'utils/validators';
import { AddressValidator } from 'components/address-validator/AddressValidator';
import { LoqateAddress } from 'resources/types/loqate';

interface AddressStepProps {
  fullAddressData: LoqateAddress | undefined;
  setFullAddressData: React.Dispatch<
    React.SetStateAction<LoqateAddress | undefined>
  >;
}

export const AddressStep = (props: AddressStepProps) => {
  const { fullAddressData, setFullAddressData } = props;

  const getCompleteAddress = (address: LoqateAddress) => {
    if (address) {
      setFullAddressData(address);
    }
  };

  /**
   * Create address content
   * -------------------------
   */

  return (
    <Box sx={{ width: '100%' }} data-testid="address-step">
      <Box>
        <AddressValidator onSelect={getCompleteAddress} />
      </Box>
      {fullAddressData && (
        <TabbedForm
          toolbar={false}
          disableInvalidFormNotification
          defaultValues={mapAddressDefaultValues(fullAddressData)}
        >
          <FormTab label="summary">
            <TextInput source="street" validate={requiredValidator} />
            <TextInput source="city" validate={requiredValidator} disabled />
            <TextInput source="line1" />
            <TextInput source="district" disabled />
            <NumberInput source="buildingNumber" />
            <TextInput source="province" disabled />
            <TextInput
              source="postalCode"
              validate={requiredValidator}
              disabled
            />
          </FormTab>
          <FormTab label="Language">
            <TextInput source="language" />
            <TextInput source="languageAlternatives" />
          </FormTab>
          <FormTab label="Building">
            <TextInput source="subBuilding" />
            <NumberInput source="buildingNumber" />
            <TextInput source="buildingName" />
            <TextInput source="block" />
            <TextInput source="company" />
            <TextInput source="department" />
          </FormTab>
          <FormTab label="Street">
            <TextInput source="secondaryStreet" />
            <TextInput source="street" validate={requiredValidator} />
          </FormTab>
          <FormTab label="Locality">
            <TextInput source="neighbourhood" />
            <TextInput source="district" disabled />
            <TextInput source="city" validate={requiredValidator} disabled />
            <TextInput source="line1" />
            <TextInput source="line2" />
            <TextInput source="line3" />
            <TextInput source="line4" />
            <TextInput source="line5" />
          </FormTab>
          <FormTab label="Admin Area">
            <TextInput source="adminAreaName" disabled />
            <TextInput source="adminAreaCode" disabled />
          </FormTab>
          <FormTab label="Province">
            <TextInput source="province" disabled />
            <TextInput source="provinceName" disabled />
            <TextInput source="provinceCode" disabled />
          </FormTab>
          <FormTab label="Country">
            <TextInput
              source="countryName"
              validate={requiredValidator}
              disabled
            />
            <TextInput source="countryIso2" disabled />
            <TextInput source="countryIso3" disabled />
            <TextInput source="countryIsoNumber" disabled />
            <TextInput
              source="postalCode"
              validate={requiredValidator}
              disabled
            />
            <TextInput source="poBoxNumber" disabled />
          </FormTab>
          <FormTab label="Geo Codes">
            <TextInput source="latitude" />
            <TextInput source="longitude" />
          </FormTab>
        </TabbedForm>
      )}
    </Box>
  );
};
