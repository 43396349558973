// core imports
import {
  FunctionField,
  RaRecord,
  ReferenceField,
  Show,
  SimpleShowLayout,
  useShowContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

// mui import
import { Box } from '@mui/material';

// components
import { TextField } from 'components/text-field/TextField';
import { ShowActions } from 'components/show-action/ShowActions';

// local imports
import { createEditPath, formatPhoneNumber } from 'utils/helper';
import { ContactTitle } from './ContactTitle';

const ContactShowActions = () => {
  const { record } = useShowContext();
  const location = useLocation();
  const path = createEditPath(location.pathname);

  return !record ? null : <ShowActions path={path} />;
};

export const ContactShow = () => (
  <Show title={<ContactTitle />} actions={<ContactShowActions />}>
    <Box sx={{ display: 'flex' }}>
      <SimpleShowLayout>
        <ReferenceField source="id" reference="outlets" label="Outlet">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Fax"
          render={(record: RaRecord) =>
            record?.fax ? formatPhoneNumber(record.fax) : '-'
          }
        />
      </SimpleShowLayout>
      <SimpleShowLayout>
        <FunctionField
          label="Phone"
          render={(record: RaRecord) =>
            record?.phone ? formatPhoneNumber(record.phone) : '-'
          }
        />
        <TextField source="email" />
      </SimpleShowLayout>
    </Box>
  </Show>
);
