// core import
import { SimpleForm } from 'react-admin';

// component import
import { Create } from 'components/create/Create';

// local component
import { MerchantForm } from './MerchantForm';

export const MerchantCreate = () => (
  <Create resourceName="Merchant">
    <SimpleForm>
      <MerchantForm />
    </SimpleForm>
  </Create>
);
