import { TextField, SimpleList } from 'react-admin';

import { List } from 'components/list/List';
import { Datagrid } from 'components/data-grid/DataGrid';
import { useSmallScreen } from 'utils/helper';
import { ListActions } from 'components/list/ListActions';

export const ServiceList = () => {
  const isSmallScreen = useSmallScreen();
  return (
    <List title="Services" actions={<ListActions showExport />}>
      {isSmallScreen ? (
        <SimpleList
          primaryText={(record) => record.name}
          tertiaryText={(record) => record.type}
          linkType="show"
        />
      ) : (
        <Datagrid rowClick="">
          <TextField source="name" />
          <TextField source="type" />
        </Datagrid>
      )}
    </List>
  );
};
