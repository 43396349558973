// configs values for auth related apis

/**
 * Name identifiers for the authorized MCS roles
 */
export enum AuthorizedRoles {
  NETWORK_MANAGER = 'network_manager',
  ADMIN = 'admin',
  MARKET_ADMIN = 'market_admin',
  READ = 'read',
}

/**
 * Cookies set for authenticated user
 */
export const AUTH_COOKIES = {
  /* Current user's token info */
  authToken: 'AUTH_TOKEN',
  /* Logged in User's Info */
  authUserInfo: 'AUTH_USER_INFO',
  /* Used to ensure if authentication API call is in Progress / completed */
  authCheckInProgress: 'AUTH_CHECK_IN_PROGRESS',
  /* Logged in account Info */
  authAccountInfo: 'AUTH_ACCOUNT_INFO',
};
