// router import
import { useSearchParams } from 'react-router-dom';

// component
import { Edit } from 'components/edit/Edit';

// local imports
import { ExpertForm } from './ExpertForm';
import { ExpertTitle } from './ExpertTitle';

export const ExpertEdit = () => {
  const [searchParams] = useSearchParams();
  const selectedOutletId = searchParams.get('outlet_id') as string | undefined;
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  let redirectTo = 'list';

  if (selectedOutletId) {
    redirectTo = `/outlets/${selectedOutletId}/experts`;

    if (selectedMerchantId) {
      redirectTo = `${redirectTo}?merchant_id=${selectedMerchantId}`;
    }
  }

  return (
    <Edit title={<ExpertTitle />} redirect={redirectTo} resourceName="Expert">
      <ExpertForm selectedOutletId={selectedOutletId} isEdit />
    </Edit>
  );
};
