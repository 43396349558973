import {
  TextField,
  ReferenceField,
  SimpleList,
  downloadCSV,
} from 'react-admin';

import jsonExport from 'jsonexport/dist';
import { List } from 'components/list/List';
import { Datagrid } from 'components/data-grid/DataGrid';
import { useSmallScreen } from 'utils/helper';

// local imports
import { ListActions } from 'components/list/ListActions';
import { OfferedServiceFilters } from './OfferedServiceFilters';

const OfferedServiceExporter = (services, fetchRelatedRecords) => {
  fetchRelatedRecords(services, 'outletId', 'outlets').then((outlets) => {
    const data = services.map((service) => ({
      ...service,
      outletName: outlets[service.outletId]
        ? outlets[service.outletId].name
        : '',
    }));

    return jsonExport(data, (err, csv) => {
      downloadCSV(csv, 'offeredServices');
    });
  });
};

export const OfferedServiceList = () => {
  const isSmallScreen = useSmallScreen();
  return (
    <List
      filters={OfferedServiceFilters}
      title="Offered Services"
      exporter={OfferedServiceExporter}
      actions={<ListActions showExport showFilter />}
    >
      {isSmallScreen ? (
        <SimpleList
          primaryText={
            <ReferenceField
              source="outletId"
              reference="outlets"
              label="Outlet"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
          }
          secondaryText={
            <ReferenceField
              source="serviceId"
              reference="services"
              label="Service"
              sortable={false}
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
          }
          tertiaryText={(record) => record.id}
          linkType="show"
        />
      ) : (
        <Datagrid rowClick="">
          <ReferenceField
            source="outletId"
            reference="outlets"
            label="Outlet"
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="serviceId"
            reference="services"
            label="Service"
            sortable={false}
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="id" />
        </Datagrid>
      )}
    </List>
  );
};
