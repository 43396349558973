import { RESOURCES } from 'resources/config/resources.config';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { ServiceList } from './ServiceList';
import { ServiceCreate } from './ServiceCreate';

export default {
  key: RESOURCES.services,
  name: RESOURCES.services,
  list: ServiceList,
  create: ServiceCreate,
  icon: SettingsApplicationsIcon,
};
