// core imports
import { RaRecord } from 'react-admin';

// components
import { Edit } from 'components/edit/Edit';

// local imports
import { MarketAreaForm, marketAreaTransForm } from './MarketAreaForm';
import { MarketAreaTitle } from './MarketAreaTitle';

export const MarketAreaEdit = () => (
  <Edit
    title={<MarketAreaTitle />}
    transform={(data?: RaRecord) => marketAreaTransForm(data)}
    resourceName="Market-Area"
  >
    <MarketAreaForm />
  </Edit>
);
