import { Buffer } from 'buffer';

const generateRandomString = (length: number): string => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

/**
 * Generate a code verifier random string
 *
 * @returns {string} A 128 character random string
 */
export const generateCodeVerifier = (): string => generateRandomString(128);

/**
 * Generate a code challenge from a code verifier
 *
 * @returns {string} A URL-safe base64 encoded SHA-256 hash of the code verifier
 */
export const generateCodeChallenge = async (
  codeVerifier: string
): Promise<string> => {
  const digest = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(codeVerifier)
  );

  return Buffer.from(digest)
    .toString('base64')
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};
