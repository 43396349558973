import {
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  RaRecord,
} from 'react-admin';
import { requiredValidator } from 'utils/validators';

type FilterType = {
  [key: string]: string | number;
};

type ReferenceOutletType = {
  source?: string;
  defaultValue?: string;
  isDisabled?: boolean;
  filter?: FilterType;
};

type SelectInputType = {
  label: string;
  optionText: string;
  disabled: boolean;
  defaultValue?: string;
};

export const ReferenceOutlet = ({
  source,
  defaultValue,
  isDisabled,
  filter,
}: ReferenceOutletType) => {
  const selectInputProps: SelectInputType = {
    label: 'Outlet',
    optionText: 'name',
    disabled: isDisabled ?? false,
  };

  if (defaultValue) {
    selectInputProps.defaultValue = defaultValue;
  }

  const optionRenderer = (choice: RaRecord) =>
    `${choice?.outletId} - ${choice?.name}`;

  return (
    <ReferenceInput
      source={source ?? 'outletId'}
      reference="outlets"
      label="Outlet"
      filter={filter ?? {}}
    >
      {defaultValue ? (
        <SelectInput {...selectInputProps} validate={requiredValidator} />
      ) : (
        <AutocompleteInput
          {...selectInputProps}
          sx={{ width: 250 }}
          validate={requiredValidator}
          optionText={optionRenderer}
        />
      )}
    </ReferenceInput>
  );
};

ReferenceOutlet.defaultProps = {
  source: 'outletId',
  defaultValue: '',
  isDisabled: false,
  filter: undefined,
};
