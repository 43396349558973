// core import
import { fetchUtils } from 'ra-core';

import { ENDPOINTS } from 'utils/api-endpoints';
import { AUTH_COOKIES } from 'utils/auth.config';
import { readFromStorage } from 'utils/helper';

/* 
This is a temporary helper function to get the access token from the cookie.
It should be removed once the API is fully migrated and instead the token should
be set in the `mcs-header.ts` utility, but the current API doesn't allow the 
Authorization header to be present so we have to set it on individual requests for now
*/
const getAccessToken = () =>
  JSON.parse(readFromStorage(AUTH_COOKIES.authToken) as string).access_token;

export const McsHttpClient = (path: string, options?: fetchUtils.Options) => {
  const url = `${ENDPOINTS.API_BASE_URL}${path}`;
  const token = getAccessToken();

  const headers = new Headers(options?.headers || {});

  if (token) {
    headers.append('X-Api-Key', token);
    headers.append('Authorization', token);
  }

  return fetchUtils.fetchJson(url, {
    user: token && {
      authenticated: true,
      token,
    },
    ...options,
    headers,
  });
};
