// core import
import { useState } from 'react';
import {
  TabbedForm,
  TextInput,
  FormTab,
  NumberInput,
  useEditContext,
  useGetOne,
  Form,
} from 'react-admin';
import { CardContent, Box } from '@mui/material';

// router
import { useLocation, useSearchParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

// component
import { AddressValidator } from 'components/address-validator/AddressValidator';
import { Edit } from 'components/edit/Edit';
import { Toolbar } from 'components/toolbar/Toolbar';

// local import
import { LoqateAddress } from 'resources/types/loqate';
import { createShowPath, mapAddressDefaultValues } from 'utils/helper';

import { ReferenceOutlet } from '../outlet/ReferenceOutlet';
import { AddressTitle } from './AddressTitle';

const AddressToolbar = ({ isGssn = false }: { isGssn: boolean }) => {
  const { record } = useEditContext();

  return !record ? null : <Toolbar isDisabledDelete={isGssn} />;
};

interface DeliveryFormProps {
  isDisabled: boolean;
  record: Record<string, unknown>;
}

const DeliveryForm = ({ isDisabled, record }: DeliveryFormProps) => (
  <Form record={record}>
    <CardContent>
      <Box
        sx={{ display: 'flex' }}
        flexDirection="column"
        alignItems="flex-start"
        margin={2}
      >
        <TextInput source="city" />
        <TextInput source="street" />
        <TextInput source="postalCode" />
        <TextInput source="countryIso2" />
      </Box>
    </CardContent>

    <AddressToolbar isGssn={isDisabled} />
  </Form>
);

interface AddressFormProps {
  address: LoqateAddress | undefined;
  type: 'delivery' | 'address';
}

const AddressForm = ({ address, type }: AddressFormProps) => {
  const { record } = useEditContext();
  let isDisabled = false;

  const { data } = useGetOne('outlets', { id: record?.id });

  if (data?.extOutletId) isDisabled = true;

  let outletFilters = {};

  if (record?.id) {
    outletFilters = { id: record?.id };
  }

  const mappedRecord = address
    ? { id: record.id, ...mapAddressDefaultValues(address) }
    : record;

  if (type === 'delivery') {
    return <DeliveryForm isDisabled={isDisabled} record={mappedRecord} />;
  }

  return (
    <TabbedForm
      toolbar={<AddressToolbar isGssn={isDisabled} />}
      disableInvalidFormNotification
      record={mappedRecord}
    >
      <FormTab label="summary">
        <TextInput source="id" label="Id" disabled />
        <TextInput source="street" />
        <TextInput source="city" />
        <TextInput source="line1" />
        <TextInput source="district" />
        <NumberInput source="buildingNumber" />
        <TextInput source="province" />
        <TextInput source="postalCode" />
        <ReferenceOutlet source="id" filter={outletFilters} isDisabled />
      </FormTab>
      <FormTab label="Language" path="language">
        <TextInput source="language" />
        <TextInput source="languageAlternatives" />
      </FormTab>
      <FormTab label="Building" path="building">
        <TextInput source="block" />
        <TextInput source="company" />
        <TextInput source="department" />
        <TextInput source="subBuilding" />
        <NumberInput source="buildingNumber" />
        <TextInput source="buildingName" />
      </FormTab>
      <FormTab label="Street" path="street">
        <TextInput source="secondaryStreet" />
        <TextInput source="street" />
      </FormTab>
      <FormTab label="Locality" path="locality">
        <TextInput source="neighbourhood" />
        <TextInput source="district" />
        <TextInput source="city" />
        <TextInput source="line1" />
        <TextInput source="line2" />
        <TextInput source="line3" />
        <TextInput source="line4" />
        <TextInput source="line5" />
      </FormTab>
      <FormTab label="Admin Area" path="adminArea">
        <TextInput source="adminAreaName" />
        <TextInput source="adminAreaCode" />
      </FormTab>
      <FormTab label="Province" path="province">
        <TextInput source="province" />
        <TextInput source="provinceName" />
        <TextInput source="provinceCode" />
      </FormTab>
      <FormTab label="Country" path="country">
        <TextInput source="countryName" />
        <TextInput source="countryIso2" />
        <TextInput source="countryIso3" />
        <TextInput source="poBoxNumber" />
      </FormTab>
      <FormTab label="Geo Codes" path="geoCodes">
        <TextInput source="latitude" format={(latitude) => latitude ?? ''} />
        <TextInput source="longitude" format={(longitude) => longitude ?? ''} />
      </FormTab>
    </TabbedForm>
  );
};

interface AddressEditProps {
  title: string;
  resource: string;
  type?: 'delivery' | 'address';
}

export const AddressEdit = ({ resource, title, type }: AddressEditProps) => {
  const [searchParams] = useSearchParams();
  const selectedOutletId = searchParams.get('outlet_id') as string | undefined;
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  let redirectTo = 'list';

  if (selectedOutletId) {
    redirectTo = `/outlets/${selectedOutletId}/${resource}`;

    if (selectedMerchantId) {
      redirectTo = `${redirectTo}?merchant_id=${selectedMerchantId}`;
    }
  }

  const location = useLocation();
  const path = createShowPath(location.pathname);
  const showConfig = { path, label: 'Show', icon: <VisibilityIcon /> };

  const [fullAddressData, setFullAddressData] = useState<
    LoqateAddress | undefined
  >();

  const getCompleteAddress = (address: LoqateAddress) => {
    if (address) {
      setFullAddressData(address);
    }
  };

  return (
    <>
      <Box>
        <AddressValidator onSelect={getCompleteAddress} />
      </Box>
      <Edit
        title={<AddressTitle title={title} />}
        redirect={redirectTo}
        showConfig={showConfig}
        resourceName="Address"
      >
        <AddressForm address={fullAddressData} type={type || 'address'} />
      </Edit>
    </>
  );
};
AddressEdit.defaultProps = {
  type: 'address',
};
