import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { Datagrid } from 'components/data-grid/DataGrid';
import { DataProvider } from 'dataProvider/DataProvider';
import { useEffect, useState } from 'react';
import {
  ListContextProvider,
  SimpleShowLayout,
  TextField,
  Title,
  useList,
} from 'react-admin';
import InboxIcon from '@mui/icons-material/Inbox';
import { GssnIssue } from 'resources/types/resources.types';
import { gssnIssueStyles } from './styles';

const GssnIssueShow = () => (
  <SimpleShowLayout>
    <TextField source="errorMessage" label="Error Message" />
    <TextField source="executionId" label="Execution Id" />
    <TextField source="executionStartTime" label="Execution Time" />
  </SimpleShowLayout>
);

const GssnIssuesEmpty = () => {
  const themeMode = useTheme().palette.mode;
  const { icon, emptyLight, emptyDark } = gssnIssueStyles;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{ color: themeMode === 'light' ? emptyLight : emptyDark }}
    >
      <InboxIcon sx={icon} />
      <Typography variant="h4" paragraph>
        No GSSN+ issues.
      </Typography>
    </Box>
  );
};

export const GssnIssueList = () => {
  const { errorMessage, datagrid, spinner } = gssnIssueStyles;

  const [gssnIssues, setGssnIssues] = useState<GssnIssue[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGssnIssues = async () => {
      try {
        setIsLoading(true);
        const { data: issues } = await DataProvider.listGssnIssues();
        setGssnIssues(issues);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGssnIssues();
  }, []);

  const listContext = useList({ data: gssnIssues });

  if (isLoading) {
    return (
      <Box sx={spinner}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ListContextProvider value={listContext}>
      <Title title="GSSN+ Issues" />
      <Datagrid
        empty={<GssnIssuesEmpty />}
        style={datagrid.layout}
        bulkActionButtons={false}
        rowClick="expand"
        sx={datagrid}
        expand={<GssnIssueShow />}
        expandSingle
      >
        <TextField source="outletName" label="Outlet Name" />
        <TextField
          source="errorMessage"
          label="Error Message"
          sx={errorMessage}
        />
      </Datagrid>
    </ListContextProvider>
  );
};
