import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { DataProvider } from 'dataProvider/DataProvider';
import { Link } from 'react-router-dom';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { GssnIssue } from 'resources/types/resources.types';
import { dashboardStyles } from './styles';

export const GssnIssues = () => {
  const { box, title, listContainer, listItemButton, spinner, errorMessage } =
    dashboardStyles;

  const [gssnIssues, setGssnIssues] = useState<GssnIssue[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGssnIssues = async () => {
      try {
        setIsLoading(true);
        const items = await DataProvider.listGssnIssues(5);
        setGssnIssues(items.data);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGssnIssues();
  }, []);

  if (isLoading) {
    return (
      <Box sx={spinner}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={box}>
      <Typography sx={title}>Latest GSSN+ Issues</Typography>
      <List sx={listContainer}>
        {gssnIssues.length === 0 ? (
          <ListItem key="1">
            <ListItemAvatar>
              <Avatar>
                <InfoOutlinedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="No GSSN+ issues found" />
          </ListItem>
        ) : (
          gssnIssues.map((issue) => (
            <ListItem key={issue.id}>
              <ListItemAvatar>
                <Avatar>
                  <SyncProblemIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemButton
                component={Link}
                to="/gssn-issues"
                sx={listItemButton}
              >
                <ListItemText
                  primary={`${issue.outletName} - ${issue.executionStartTime}`}
                  secondary={
                    <Typography variant="body2" sx={errorMessage}>
                      {issue.errorMessage}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
};
