import { getAllTimezones } from 'countries-and-timezones';

type TzType = {
  name: string;
  id: string;
};

export const ERROR_MESSAGE = 'Until field should be greater than From field';
const dateForOpeningHours = '1/1/2011';

export const getEuropeTimeZones = (): TzType[] => {
  const timezones = getAllTimezones();
  const europeTimezones = Object.keys(timezones).filter(
    (item) => item.indexOf('Europe') > -1
  );
  const formattedTzList: TzType[] = [];

  europeTimezones.forEach((item) => {
    const tz: TzType = {
      name: timezones[item].name,
      id: timezones[item].dstOffsetStr,
    };

    formattedTzList.push(tz);
  });
  return formattedTzList;
};

export const formatTime = (time: string): string | undefined => {
  if (time) {
    let formattedTime = time;

    // check if timezone is appended
    if (time.indexOf('+') > -1) {
      formattedTime = time.substring(0, time.indexOf('+'));
    }

    // get the hour from the time
    let hour = +formattedTime.substring(0, 2);
    const timeSuffix = hour < 12 ? 'AM' : 'PM';
    hour = hour % 12 || 12;

    return `${hour.toString().padStart(2, '0')}${formattedTime.substring(
      2,
      5
    )} ${timeSuffix}`;
  }

  return undefined;
};

export const validateOpeningHours = (from, until) => {
  const tStart: any = new Date(`${dateForOpeningHours} ${from}`);
  const tEnd: any = new Date(`${dateForOpeningHours} ${until}`);
  const dMilliseconds: any = tEnd - tStart;
  let isTimeInValid = false;

  if (dMilliseconds < 0) {
    isTimeInValid = true;
  }

  return isTimeInValid;
};
