// core imports
import {
  ReferenceManyField,
  Datagrid as RaDatagrid,
  ReferenceField,
  TextField,
} from 'react-admin';

// component
import { Datagrid } from 'components/data-grid/DataGrid';
import {
  FromFunctionField,
  UntilFunctionField,
} from 'resources/opening-hour/OpeningHoursList';
import { WeekdayField } from 'components/weekday-field/WeekdayField';

export const OuterServicesShow = () => (
  <ReferenceManyField
    reference="offered-services"
    label="Offered Services"
    target="outletId"
  >
    <Datagrid
      rowClick=""
      isRowSelectable={() => false}
      bulkActionButtons={false}
      empty={<> No offered services found</>}
    >
      <ReferenceField
        source="serviceId"
        reference="services"
        label="Offered-Services"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>

      <ReferenceManyField reference="opening-hours" target="offeredServiceId">
        <RaDatagrid
          bulkActionButtons={false}
          isRowSelectable={() => false}
          empty={<>No opening hours found</>}
        >
          <FromFunctionField reference="services" source="from" />
          <UntilFunctionField source="until" />
          <WeekdayField source="weekDay" />
        </RaDatagrid>
      </ReferenceManyField>
    </Datagrid>
  </ReferenceManyField>
);
