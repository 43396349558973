import { TextInput } from 'react-admin';

export const MerchantFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <TextInput source="companyId" label="Company ID" />,
  <TextInput source="type" />,
  <TextInput source="extCompanyId" label="External Company ID" />,
  <TextInput source="investorName" label="Investor Name" />,
  <TextInput source="merchantName" label="Partner Name" />,
  <TextInput source="vat" />,
];
