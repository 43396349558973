import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  RaRecord,
} from 'react-admin';

const optionRenderer = (choice: RaRecord) =>
  `${choice?.outletId} - ${choice?.name}`;

export const ContactFilters = [
  <TextInput label="Search phone, email" source="q" alwaysOn />,
  <TextInput source="email" label="Email" />,
  <TextInput source="phone" label="Phone" />,
  <ReferenceInput label="Outlet" reference="outlets" source="id">
    <AutocompleteInput
      optionText={optionRenderer}
      label="Outlet"
      sx={{ width: 220 }}
    />
  </ReferenceInput>,
];
