// hook import
import { useSearchParams } from 'react-router-dom';

// component import
import { Create } from 'components/create/Create';

// local import
import { ExpertForm } from './ExpertForm';

export const ExpertCreate = () => {
  const [searchParams] = useSearchParams();
  const selectedOutletId = searchParams.get('outlet_id') as string | undefined;
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  let redirectTo = 'list';

  if (selectedOutletId) {
    redirectTo = `/outlets/${selectedOutletId}/experts`;

    if (selectedMerchantId) {
      redirectTo = `${redirectTo}?merchant_id=${selectedMerchantId}`;
    }
  }

  return (
    <Create redirect={redirectTo} resourceName="Expert">
      <ExpertForm selectedOutletId={selectedOutletId} />
    </Create>
  );
};
