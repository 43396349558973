import { HttpError } from 'react-admin';
import { MARKET_TYPES } from 'resources/config/resources.config';
import { Error } from 'resources/types/resources.types';

/**
 * @method getErrorMessage
 * Method to fetch the error message
 * @param error
 * @returns string | unknown
 */
export const getErrorMessage = (error: Error | unknown): string | unknown => {
  if (!error) {
    return '';
  }

  try {
    // capture the server error and display as notification
    let errorMsg = JSON.parse(JSON.stringify(error))?.body?.error
      .shortDescription;

    // replace merchant to partner as we get merchant from backend in the error msg
    errorMsg = errorMsg?.replaceAll('merchant', 'partner');

    return errorMsg;
  } catch (err) {
    return err;
  }
};

interface McsRecord {
  [key: string]: any;
}

/**
 * @method transform
 * Method to transform the form inputs before sending to API
 * It changes the empty string inputs to null and sanitizes the data
 * @param data
 * @returns data | undefined
 */
export const transform = (data?: McsRecord): McsRecord | undefined => {
  if (!data) {
    return undefined;
  }

  const sanitizedData = {};

  for (const key in data) {
    if (typeof data[key] === 'string' && data[key].length === 0) {
      sanitizedData[key] = null;
    } else {
      sanitizedData[key] =
        typeof data[key] === 'string' ? data[key].trim() : data[key];
    }
  }

  return sanitizedData;
};

/**
 * Format the error response from the API to work with react admins error handling.
 *
 * @param error the original error response
 * @returns a formatted error including the error code and description
 */
export const formatError = (error: HttpError): HttpError => {
  if (!error?.body?.errors) {
    return error;
  }

  error.body.error = {
    errorCode: error.body.errors[0].errorCode,
    shortDescription: error.body.errors[0].shortDescription,
  };

  delete error.body.errors;

  return error;
};

/**
 * Cleans the file name by removing the time part of the timestamp and adds a .xlsx extension.
 *
 * This function takes a file name string that contains a timestamp and modifies it by
 * removing the time portion (e.g., "T09:20:47.954Z") and appending the ".xlsx" extension.
 *
 * @param {string} fileName - The original file name with timestamp.
 * @returns {string} - The cleaned file name with ".xlsx" extension.
 *
 * @example
 * // Returns "outletDetails-2024-06-25.xlsx"
 * cleanFileName("outletDetails-2024-06-25T09:20:47.954Z");
 */
export const cleanFileName = (fileName: string) =>
  `${fileName.replace(/T\d{2}:\d{2}:\d{2}\.\d{3}Z$/, '')}.xlsx`;

/**
 * Maps the market filter to matching country codes.
 * If no match is found, the market filter is returned as is.
 *
 * @param {string} market The market filter
 * @returns {string[]} The market filter mapped to matching country codes
 */
export const mapToCountryCodes = (market: string): string[] => {
  const normalizedMarket = market.trim().toLowerCase();

  const countries = MARKET_TYPES.filter((marketType) =>
    marketType.name.toLowerCase().includes(normalizedMarket)
  ).map((marketType) => marketType.id);

  return countries.length > 0 ? countries : [market];
};

/**
 *  Transforms the filter object to match the API requirements
 *
 * @param {Record<string, any>} filter The filter object
 * @returns {Record<string, any>} The transformed filter object
 */
export const transformFilter = (
  filter: Record<string, any>
): Record<string, any> => {
  const transformedFilter = { ...filter };

  if (transformedFilter.market) {
    transformedFilter.market = mapToCountryCodes(filter.market);
  }

  return transformedFilter;
};
