import { Box } from '@mui/material';
import { TextField } from 'components/text-field/TextField';
import {
  DateField,
  FunctionField,
  RaRecord,
  ReferenceField,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import { CONTRACT_TYPES } from 'resources/config/resources.config';

export const ContractShow = () => (
  <Show>
    <Box sx={{ display: 'flex' }}>
      <SimpleShowLayout>
        <ReferenceField
          source="outletId"
          reference="outlets"
          label="Outlet"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Contract Type"
          source="contractType"
          render={(record: RaRecord) =>
            CONTRACT_TYPES.find((type) => type.id === record.contractType)
              ?.name || '-'
          }
        />
        <TextField source="contractName" label="Subject matter" />
      </SimpleShowLayout>
      <SimpleShowLayout>
        <DateField source="startDate" />
        <DateField source="endDate" />
        <TextField source="comment" />
      </SimpleShowLayout>
    </Box>
  </Show>
);
