import { TextField, RaRecord, SimpleList } from 'react-admin';

import { List } from 'components/list/List';
import { Datagrid } from 'components/data-grid/DataGrid';
import { useSmallScreen } from 'utils/helper';
import { ListActions } from 'components/list/ListActions';
import { AddressFilters } from './AddressFilters';

const DeliveryInfo = () => (
  <Datagrid
    isRowSelectable={(record: RaRecord | undefined) =>
      !record?.externalOutletId
    }
  >
    <TextField source="city" />
    <TextField source="street" />
    <TextField source="postalCode" />
    <TextField source="countryIso2" />
  </Datagrid>
);

const AddressInfo = () => (
  <Datagrid
    isRowSelectable={(record: RaRecord | undefined) =>
      !record?.externalOutletId
    }
  >
    <TextField source="outlet" label="Outlet" sortable={false} />
    <TextField source="street" />
    <TextField source="buildingNumber" />
    <TextField source="postalCode" />
    <TextField source="city" />
    <TextField source="countryName" />
    <TextField source="provinceName" />
    <TextField source="provinceCode" />
    <TextField source="latitude" />
    <TextField source="longitude" />
  </Datagrid>
);

interface AddressListProps {
  title: string;
  type?: 'delivery' | 'address';
}

export const AddressList = ({ title, type }: AddressListProps) => {
  const isSmallScreen = useSmallScreen();
  const info = type === 'delivery' ? <DeliveryInfo /> : <AddressInfo />;

  return (
    <List
      filters={AddressFilters}
      exporter={false}
      title={title}
      actions={<ListActions showFilter />}
    >
      {isSmallScreen ? (
        <SimpleList
          primaryText={(record) => record.outlet}
          secondaryText={(record) => record.street}
          tertiaryText={(record) => record.province}
          linkType="show"
        />
      ) : (
        info
      )}
    </List>
  );
};

AddressList.defaultProps = {
  type: 'address',
};
