// core import
import { useGetOne, useRecordContext } from 'react-admin';

/**
 * MarketAreaTitle Component
 * @returns <MarketAreaTitle> ReactNode
 */
export const MarketAreaTitle = () => {
  const record = useRecordContext();
  const { data: merchant } = useGetOne('merchants', { id: record?.merchantId });

  return !record ? null : (
    <span>
      Market Area {merchant?.companyId} - {record?.name}
    </span>
  );
};
