// component import
import { Create } from 'components/create/Create';

// local import
import { ExpertPositionForm } from './ExpertPositionForm';

export const ExpertPositionCreate = () => (
  <Create resourceName="Expert-position">
    <ExpertPositionForm />
  </Create>
);
