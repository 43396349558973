// core import
import { useRecordContext } from 'react-admin';

// component
import { Edit } from 'components/edit/Edit';

// local import
import { ExpertPositionForm } from './ExpertPositionForm';

const ExpertPositionTitle = () => {
  const record = useRecordContext();

  return !record ? null : (
    <span>
      Expert Position {record?.id} - {record?.position}
    </span>
  );
};

export const ExpertPositionEdit = () => (
  <Edit title={<ExpertPositionTitle />} resourceName="Expert-position">
    <ExpertPositionForm />
  </Edit>
);
