// core imports
import { SimpleForm, TextInput, SelectInput } from 'react-admin';

// mui-styles
import { Box } from '@mui/material';

import { requiredValidator } from 'utils/validators';
import { SERVICE_TYPES } from '../config/resources.config';

export const ServiceForm = () => (
  <SimpleForm disableInvalidFormNotification>
    <Box flex="1" mt={-1}>
      <Box display="grid">
        <TextInput source="name" fullWidth validate={requiredValidator} />
        <SelectInput source="type" choices={SERVICE_TYPES} required />
      </Box>
    </Box>
  </SimpleForm>
);
