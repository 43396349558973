import { Box } from '@mui/material';
import { DateInput, SelectInput, TextInput } from 'react-admin';
import { CONTRACT_TYPES } from 'resources/config/resources.config';
import { endDateValidator, requiredValidator } from 'utils/validators';

export const ContractStep = () => (
  <Box flex="1" mt={-1} data-testid="contract-step">
    <Box display="flex">
      <SelectInput
        source="contractType"
        validate={requiredValidator}
        choices={CONTRACT_TYPES}
      />
    </Box>
    <Box display="flex">
      <TextInput
        source="contractName"
        label="Subject matter"
        fullWidth
        validate={requiredValidator}
      />
    </Box>
    <Box display="flex">
      <DateInput source="startDate" validate={requiredValidator} />
    </Box>
    <Box display="flex">
      <DateInput source="endDate" fullWidth validate={endDateValidator} />
    </Box>
    <Box display="flex">
      <TextInput source="comment" fullWidth />
    </Box>
  </Box>
);
