import { useState } from 'react';
import {
  Button,
  Confirm,
  useListContext,
  useDeleteMany,
  useNotify,
  useRefresh,
  useUnselectAll,
  useResourceContext,
} from 'react-admin';

const DeleteButton = () => {
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const resource = useResourceContext();
  const resourceText =
    useResourceContext().charAt(0).toUpperCase() +
    useResourceContext().slice(1);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const [deleteMany, { isLoading }] = useDeleteMany(
    resource,
    { ids: selectedIds },
    {
      onSuccess: () => {
        notify(`${resourceText} successfully deleted!`, { type: 'success' });
        unselectAll();
      },
      onError: () => {
        notify(`Could not delete ${resourceText}!`, { type: 'error' });
        refresh();
      },
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    deleteMany();
    setOpen(false);
  };

  return (
    <>
      <Button
        label="Delete"
        onClick={handleClick}
        sx={{
          backgroundColor: '#ff0000bd',
          color: 'white',
          '&:hover': {
            backgroundColor: '#e64040',
          },
        }}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Delete the selected resource(s)"
        content="Are you sure you want to delete the resource(s)?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default DeleteButton;
