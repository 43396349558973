// core imports
import { FunctionField, RaRecord, SimpleList } from 'react-admin';

// components
import { List } from 'components/list/List';
import { Datagrid } from 'components/data-grid/DataGrid';
import { TextField } from 'components/text-field/TextField';

// config
import { notApplicable, MARKET_TYPES } from 'resources/config/resources.config';

// utils
import { parseVat, useSmallScreen } from 'utils/helper';

// local import
import { PaletteMode, useTheme } from '@mui/material';
import { ListActions } from 'components/list/ListActions';
import { MerchantFilters } from './MerchantFilters';

const merchantRowStyle = (record: RaRecord, themeMode: PaletteMode) => {
  const colors =
    themeMode === 'light'
      ? {
          default: '#FFF',
          secondary: '#e0e0e075',
        }
      : {
          default: '#222222',
          secondary: '#121212',
        };

  return {
    backgroundColor:
      !record.vat || !record.merchantName ? colors.secondary : colors.default,
  };
};

export const MerchantList = () => {
  const isSmallScreen = useSmallScreen();
  const themeMode = useTheme().palette.mode;
  return (
    <List
      filters={MerchantFilters}
      actions={<ListActions showExport showFilter />}
      title="Partners"
    >
      {isSmallScreen ? (
        <SimpleList
          primaryText={(record) => record.companyId}
          secondaryText={(record) => record.merchantName}
          tertiaryText={(record) => record.extCompanyId}
          linkType="show"
        />
      ) : (
        <Datagrid
          rowStyle={(record: RaRecord) => merchantRowStyle(record, themeMode)}
          isRowSelectable={(record: RaRecord | undefined) =>
            !record?.extCompanyId
          }
        >
          <TextField source="companyId" label="Company Id" />
          <TextField source="merchantName" label="Partner Name" />
          <TextField
            source="extCompanyId"
            label="External Company Id"
            emptyText={notApplicable}
          />
          <TextField source="investorName" label="Investor Name" />
          <TextField source="type" label="Type" />
          <TextField source="vat" />
          <FunctionField
            label="Country"
            source="vat"
            render={(record: RaRecord) => {
              const market = MARKET_TYPES.find(
                (marketTypes) => marketTypes.id === parseVat(record.vat)
              );
              return market?.name ?? '-';
            }}
          />
        </Datagrid>
      )}
    </List>
  );
};
