import {
  TextField,
  ReferenceField,
  RaRecord,
  downloadCSV,
  SimpleList,
  BooleanField,
  FunctionField,
  EmailField,
} from 'react-admin';

import { DataProvider } from 'dataProvider/DataProvider';

import { PaletteMode, useTheme } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import { List } from 'components/list/List';
import { Datagrid } from 'components/data-grid/DataGrid';
import { formatPhoneNumber, useSmallScreen } from 'utils/helper';

import { ListActions } from 'components/list/ListActions';
import { ExpertFilters } from './ExpertFilters';

const ExpertExporter = async (experts) => {
  try {
    const { data: outlets } = await DataProvider.getList('outlets', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: {},
    });

    const expertsWithOutlet = experts.map((expert) => {
      const outletField = outlets.find(
        (outlet) => outlet.id === expert.outletId
      );
      return {
        ...expert,
        outletName: outletField?.name,
      };
    });

    const csv = await jsonExport(expertsWithOutlet);
    downloadCSV(csv, 'experts');
  } catch (error) {
    console.debug('Something went wrong when trying to export to csv', error);
  }
};

const expertRowStyle = (record: RaRecord, themeMode: PaletteMode) => {
  const colors =
    themeMode === 'light'
      ? {
          default: '#FFF',
          secondary: '#e0e0e075',
        }
      : {
          default: '#222222',
          secondary: '#121212',
        };

  return {
    backgroundColor: !record.gigyaId ? colors.secondary : colors.default,
  };
};
const ExpertRegularScreen = ({ themeMode }) => (
  <Datagrid rowStyle={(record) => expertRowStyle(record, themeMode)}>
    <ReferenceField
      source="outletId"
      reference="outlets"
      label="Outlet"
      sortable={false}
    >
      <TextField source="name" />
    </ReferenceField>
    <TextField source="firstName" />
    <TextField source="lastName" />
    <EmailField source="email" />
    <FunctionField
      label="Phone"
      render={(record: RaRecord) =>
        record?.phone ? formatPhoneNumber(record.phone) : ''
      }
    />
    <ReferenceField
      source="typeId"
      reference="expert-types"
      label="Expert-type"
      link={false}
    >
      <TextField source="type" />
    </ReferenceField>
    <ReferenceField
      source="positionId"
      reference="expert-positions"
      link={false}
    >
      <TextField source="position" />
    </ReferenceField>
    <BooleanField source="isActive" label="Is Active" />
    <BooleanField source="gigyaId" label="Gigya Id" looseValue />
    <FunctionField
      label="Languages"
      source="languages"
      render={(record: RaRecord) =>
        record?.languages ? `${record?.languages?.join(', ')}` : ''
      }
    />
  </Datagrid>
);

const ExpertSmallScreen = () => (
  <SimpleList
    primaryText={(record) => record.lastName}
    secondaryText={(record) => record.firstName}
    tertiaryText={(record) => record.phone}
    linkType="show"
  />
);

export const ExpertList = () => {
  const isSmallScreen = useSmallScreen();
  const themeMode = useTheme().palette.mode;

  return (
    <List
      filters={ExpertFilters}
      exporter={ExpertExporter}
      title="Experts"
      actions={<ListActions showExport />}
    >
      {isSmallScreen ? (
        <ExpertSmallScreen />
      ) : (
        <ExpertRegularScreen themeMode={themeMode} />
      )}
    </List>
  );
};
