// Core imports
import { Title, usePermissions } from 'react-admin';
import { Helmet } from 'react-helmet';

// material ui styles
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// import component
import { Dashboard } from 'components/dashboard/Dashboard';

import { readFromStorage } from 'utils/helper';

const unauthorizedUser = () => {
  const accountInfo = JSON.parse(
    readFromStorage('AUTH_ACCOUNT_INFO') as string
  );

  return (
    <>
      <Helmet>
        <title>MCS Dashboard</title>
      </Helmet>
      <Card>
        <Title title="Dashboard" />
        {!accountInfo && <CardContent />}
        {accountInfo && (
          <CardContent>
            User does not have access to this system. (
            {accountInfo?.profile?.email})
          </CardContent>
        )}
      </Card>
    </>
  );
};

export const DashboardWrapper = () => {
  const { isLoading, permissions: authPerm } = usePermissions();

  if (isLoading) {
    return <>Loading...</>;
  }

  if (authPerm) {
    return (
      <>
        <Helmet>
          <title>MCS Dashboard</title>
        </Helmet>
        <Card>
          <Title title="Dashboard" />
        </Card>
        <Dashboard />
      </>
    );
  }
  return <>{unauthorizedUser()}</>;
};
