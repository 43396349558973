// core imports
import {
  useEditController,
  TabbedForm,
  FormTab,
  useEditContext,
  useGetList,
} from 'react-admin';

// router import
import { useLocation, useSearchParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

// components
import { Edit } from 'components/edit/Edit';
import { Toolbar } from 'components/toolbar/Toolbar';

// local imports
import { createShowPath } from 'utils/helper';
import { OutletForm } from './OutletForm';
import { OutletAddress } from './OutletAddress';
import { OutletContact } from './OutletContact';
import { OutletExperts } from './OutletExperts';
import { OutletServices } from './OutletServices';
import { OutletTitle } from './OutletTitle';
import { OutletContracts } from './outlet-contracts/OutletContracts';

const OutletToolbar = () => {
  const { record } = useEditContext();

  return !record ? null : (
    <Toolbar
      isDisabledDelete={!!record?.extOutletId}
      deleteLabel="Delete Outlet"
    />
  );
};

export const OutletEdit = () => {
  const controllerProps = useEditController();
  const isGssnRecord = controllerProps?.record?.extOutletId;
  const { data: allServices } = useGetList('services', {
    pagination: { page: 1, perPage: 100 },
  });

  const [searchParams] = useSearchParams();
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  const location = useLocation();
  const path = createShowPath(location.pathname);
  const showConfig = { path, label: 'Show', icon: <VisibilityIcon /> };

  return (
    <Edit
      showConfig={showConfig}
      title={<OutletTitle />}
      resourceName="Outlet"
      redirect={
        selectedMerchantId
          ? `/merchants/${selectedMerchantId}/outlets`
          : '/outlets'
      }
    >
      <TabbedForm
        toolbar={<OutletToolbar />}
        mode="onChange"
        reValidateMode="onChange"
      >
        <FormTab label="summary">
          <OutletForm isEditable isDisabled={!!isGssnRecord} />
        </FormTab>
        <FormTab label="Services" path="services">
          <OutletServices
            selectedOutletId={controllerProps?.record?.id}
            allServices={allServices}
          />
        </FormTab>
        <FormTab label="Address" path="addresses">
          <OutletAddress resource="addresses" title="Address" />
        </FormTab>
        <FormTab label="Delivery Address" path="delivery-addresses">
          <OutletAddress
            resource="delivery-addresses"
            title="Delivery Address"
            type="delivery"
          />
        </FormTab>
        <FormTab label="Contact" path="contacts">
          <OutletContact />
        </FormTab>
        <FormTab label="Experts" path="experts">
          <OutletExperts />
        </FormTab>
        <FormTab label="Contracts" path="contracts">
          <OutletContracts />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
