import {
  TextField,
  EmailField,
  RaRecord,
  ReferenceField,
  BooleanField,
  SimpleList,
  FunctionField,
} from 'react-admin';

// local imports
import { List } from 'components/list/List';
import { Datagrid } from 'components/data-grid/DataGrid';
import { ListActions } from 'components/list/ListActions';
import { formatPhoneNumber, useSmallScreen } from 'utils/helper';
import { ContactFilters } from './ContactFilters';

export const ContactList = () => {
  const isSmallScreen = useSmallScreen();

  return (
    <List
      exporter={false}
      filters={ContactFilters}
      title="Contacts"
      actions={<ListActions showFilter />}
    >
      {isSmallScreen ? (
        <SimpleList
          primaryText={(record) => record.outlet}
          secondaryText={(record) => record.email}
          tertiaryText={(record) => record.phone}
          linkType="show"
        />
      ) : (
        <Datagrid
          isRowSelectable={(record: RaRecord | undefined) =>
            !record?.externalOutletId
          }
        >
          <ReferenceField
            source="id"
            reference="outlets"
            sortable={false}
            label="Outlet"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="id"
            reference="outlets"
            sortable={false}
            label="Is Active"
          >
            <BooleanField source="isActive" label="Is Active" />
          </ReferenceField>
          <FunctionField
            label="Phone"
            render={(record: RaRecord) =>
              record?.phone ? formatPhoneNumber(record.phone) : ''
            }
          />
          <EmailField source="email" />
        </Datagrid>
      )}
    </List>
  );
};
