import { TextField, usePermissions } from 'react-admin';

import { List } from 'components/list/List';
import { Datagrid } from 'components/data-grid/DataGrid';
import { ListActions } from 'components/list/ListActions';
import { AuthorizedRoles } from 'utils/auth.config';

export const ExpertPositionList = () => {
  const { permissions } = usePermissions();
  const showEdit = permissions !== AuthorizedRoles.READ;

  return (
    <List title="Expert Positions" actions={<ListActions showExport />}>
      <Datagrid rowClick={showEdit ? 'edit' : false}>
        <TextField source="position" />
      </Datagrid>
    </List>
  );
};
