import { RESOURCES } from 'resources/config/resources.config';
import ManagerAccountsIcon from '@mui/icons-material/ManageAccounts';
import { ExpertPositionList } from './ExpertPositionList';
import { ExpertPositionEdit } from './ExpertPositionEdit';
import { ExpertPositionCreate } from './ExpertPositionCreate';

export default {
  key: RESOURCES.expertPositions,
  name: RESOURCES.expertPositions,
  list: ExpertPositionList,
  edit: ExpertPositionEdit,
  create: ExpertPositionCreate,
  icon: ManagerAccountsIcon,
  options: { label: 'Expert Positions' },
};
