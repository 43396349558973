import { RESOURCES } from 'resources/config/resources.config';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { OpeningHoursList } from './OpeningHoursList';
import { OpeningHoursEdit } from './OpeningHoursEdit';
import { OpeningHoursCreate } from './OpeningHoursCreate';
import { OpeningHoursShow } from './OpeningHoursShow';

export default {
  key: RESOURCES.openingHours,
  name: RESOURCES.openingHours,
  list: OpeningHoursList,
  create: OpeningHoursCreate,
  edit: OpeningHoursEdit,
  show: OpeningHoursShow,
  icon: AccessTimeIcon,
  options: { label: 'Opening Hours' },
};
