import useMediaQuery from '@mui/material/useMediaQuery';
import parsePhoneNumber from 'libphonenumber-js';
import { LoqateAddress } from 'resources/types/loqate';

/**
 * @method setToStorage
 * Used to store items in storage
 *
 * @param storageItem: string
 * @param value: string
 * @return {void}
 */
export const setToStorage = (storageItem: string, value: string): void => {
  sessionStorage.setItem(storageItem, value);
};

/**
 * @method readFromStorage
 * Used to fetch the item in storage
 *
 * @param storageItem: string
 * @returns item: string
 */
export const readFromStorage = (storageItem: string) =>
  sessionStorage.getItem(storageItem);

/**
 * @method clearStorage
 * Used to clear item in storage
 *
 * @param {void}
 * @returns {void}
 */
export const clearStorage = (): void => sessionStorage.clear();

/**
 * Construct the path for the edit page
 *
 * @param {string} pathName - the current path
 * @return {string} the path for the edit page
 */
export const createEditPath = (pathName: string): string => {
  const editPath = pathName.replace('/show', '');
  return editPath;
};

/**
 * Construct the path for the show page
 *
 * @param {string} pathName - the current path
 * @return {string} the path for the show page
 */
export const createShowPath = (pathName: string): string => {
  // First tab always ends with an id so "/show" should be added to the end of the path
  if (pathName.match(/\d+$/)) {
    return `${pathName}/show`;
  }

  const showPath = `${pathName.slice(
    0,
    pathName.lastIndexOf('/')
  )}/show${pathName.slice(pathName.lastIndexOf('/'))}`;

  return showPath;
};

/**
 * Format phone number to international format
 *
 * @param {string} phoneNumber - phone number to format
 * @return {string} Phone number in international format or the original number if it cannot be parsed
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
  const parsedNumber = parsePhoneNumber(phoneNumber);
  return parsedNumber?.formatInternational() || phoneNumber;
};

export const useSmallScreen = () => useMediaQuery('(max-width:900px)');

/**
 * Map loqate address to default values for address form
 *
 * @param {LoqateAddress | undefined} address - Loqate address object obtained from the address state, if available.
 * @returns {Record<string, any>} - Object with default values for address form
 */
export const mapAddressDefaultValues = (
  address: LoqateAddress | undefined
): Record<string, any> => {
  if (!address) {
    return {
      street: '',
      city: '',
      line1: '',
      district: '',
      buildingNumber: '',
      province: '',
      postalCode: '',
      language: '',
      languageAlternatives: '',
      subBuilding: '',
      buildingName: '',
      block: '',
      company: '',
      department: '',
      secondaryStreet: '',
      neighbourhood: '',
      line2: '',
      line3: '',
      line4: '',
      line5: '',
      adminAreaName: '',
      adminAreaCode: '',
      provinceName: '',
      provinceCode: '',
      countryName: '',
      countryIso2: '',
      countryIso3: '',
      countryIsoNumber: '',
      poBoxNumber: '',
      latitude: '',
      longitude: '',
    };
  }

  return {
    street: address.Street,
    city: address.City,
    line1: address.Line1,
    district: address.District,
    buildingNumber: address.BuildingNumber,
    province: address.Province,
    postalCode: address.PostalCode,
    language: address.Language,
    languageAlternatives: address.LanguageAlternatives,
    subBuilding: address.SubBuilding,
    buildingName: address.BuildingName,
    block: address.Block,
    company: address.Company,
    department: address.Department,
    secondaryStreet: address.SecondaryStreet,
    neighbourhood: address.Neighbourhood,
    line2: address.Line2,
    line3: address.Line3,
    line4: address.Line4,
    line5: address.Line5,
    adminAreaName: address.AdminAreaName,
    adminAreaCode: address.AdminAreaCode,
    provinceName: address.ProvinceName,
    provinceCode: address.ProvinceCode,
    countryName: address.CountryName,
    countryIso2: address.CountryIso2,
    countryIso3: address.CountryIso3,
    countryIsoNumber: address.CountryIsoNumber,
    poBoxNumber: address.POBoxNumber,
    latitude: address.Field1,
    longitude: address.Field2,
  };
};

/**
 * Parse VAT number to get the country code
 *
 * @param {string} vat - VAT number
 * @returns {string} - Country code
 */
export const parseVat = (vat: string): string => {
  if (!vat) {
    return '';
  }
  const countryCode = vat.slice(0, 2);
  return countryCode.toLowerCase();
};
