import { TextInput, SimpleForm } from 'react-admin';
import { Box } from '@mui/material';

export const ExpertPositionForm = () => (
  <SimpleForm disableInvalidFormNotification>
    <Box flex="1" mt={-1}>
      <Box display="flex" width={430}>
        <TextInput source="position" fullWidth required />
      </Box>
    </Box>
  </SimpleForm>
);
