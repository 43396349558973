// component import
import { Create } from 'components/create/Create';

// local import
import { ServiceForm } from './ServiceForm';

export const ServiceCreate = () => (
  <Create resourceName="Service">
    <ServiceForm />
  </Create>
);
