import { useEffect, useState } from 'react';

/**
 * Debounce hook to delay the update of a value.
 * This hook delays the update of a value until a specified time has passed since the last change.
 *
 * @param {string} value the value to debounce
 * @param {number} delay the number of milliseconds to wait before updating the debounced value
 * @returns {string} debounced value
 */
export const useDebounce = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
