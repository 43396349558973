// core imports
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceField,
  NumberField,
  useShowContext,
  SimpleShowLayout,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

// mui import
import { Box } from '@mui/material';

// components
import { ShowActions } from 'components/show-action/ShowActions';

// local import
import { createEditPath } from 'utils/helper';
import { AddressTitle } from './AddressTitle';

const AddressShowActions = () => {
  const { record } = useShowContext();
  const location = useLocation();
  const path = createEditPath(location.pathname);

  return !record ? null : <ShowActions path={path} />;
};

const AddressData = () => (
  <>
    <TabbedShowLayout>
      <Tab label="summary">
        <Box sx={{ display: 'flex' }}>
          <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="street" />
            <TextField source="city" />
            <TextField source="line1" />
            <TextField source="district" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <NumberField source="buildingNumber" />
            <TextField source="provinceName" />
            <TextField source="postalCode" />
            <ReferenceField source="id" reference="outlets" label="Outlet">
              <TextField source="name" />
            </ReferenceField>
          </SimpleShowLayout>
        </Box>
      </Tab>
      <Tab label="Address" path="Address">
        <Box sx={{ display: 'flex' }}>
          <SimpleShowLayout>
            <TextField source="language" />
            <TextField source="subBuilding" />
            <NumberField source="buildingNumber" />
            <TextField source="buildingName" />
            <TextField source="secondaryStreet" />
            <TextField source="street" />
            <TextField source="neighbourhood" />
            <TextField source="district" />
            <TextField source="city" />
            <TextField source="line1" />
            <TextField source="line2" />
            <TextField source="line3" />
            <TextField source="line4" />
            <TextField source="line5" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="languageAlternatives" />
            <TextField source="adminAreaName" />
            <TextField source="adminAreaCode" />
            <TextField source="province" />
            <TextField source="provinceName" />
            <TextField source="provinceCode" />
            <TextField source="countryName" />
            <TextField source="countryIso2" />
            <TextField source="countryIso3" />
            <TextField source="latitude" />
            <TextField source="longitude" />
          </SimpleShowLayout>
        </Box>
      </Tab>
    </TabbedShowLayout>
  </>
);

export const DeliveryData = () => (
  <SimpleShowLayout>
    <TextField source="city" />
    <TextField source="street" />
    <TextField source="postalCode" />
    <TextField source="countryIso2" />
  </SimpleShowLayout>
);

interface AddressShowProps {
  title: string;
  type?: 'delivery' | 'address';
}

export const AddressShow = ({ type, title }: AddressShowProps) => (
  <Show title={<AddressTitle title={title} />} actions={<AddressShowActions />}>
    {type === 'delivery' ? <DeliveryData /> : <AddressData />}
  </Show>
);

AddressShow.defaultProps = {
  type: 'address',
};
