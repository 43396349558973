import { RESOURCES } from 'resources/config/resources.config';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { OutletsList } from './OutletList';
import { OutletEdit } from './OutletEdit';
import { OutletCreateWizard } from './outlet-wizard/OutletCreateWizard';
import { OutletShow } from './outlet-show/OutletShow';

export default {
  key: RESOURCES.outlets,
  name: RESOURCES.outlets,
  list: OutletsList,
  edit: OutletEdit,
  create: OutletCreateWizard,
  show: OutletShow,
  icon: StorefrontIcon,
};
