// core imports
import { Show, TabbedShowLayout, Tab, useShowController } from 'react-admin';
import { ShowActions } from 'components/show-action/ShowActions';
import { useLocation } from 'react-router-dom';

// local imports
import { createEditPath } from 'utils/helper';
import { OutletDetailsShow } from './OutletDetailsShow';
import { OutletContact } from '../OutletContact';
import { OutletExperts } from '../OutletExperts';
import { OutletTitle } from '../OutletTitle';
import { OutletAddress } from '../OutletAddress';
import { OuterServicesShow } from './OutletServicesShow';
import { OutletContracts } from '../outlet-contracts/OutletContracts';

export const OutletShow = () => {
  const { record } = useShowController();
  const location = useLocation();
  const path = createEditPath(location.pathname);

  if (record) {
    return (
      <Show title={<OutletTitle />} actions={<ShowActions path={path} />}>
        <TabbedShowLayout record={record}>
          <Tab label="Summary">
            <OutletDetailsShow />
          </Tab>
          <Tab label="Services" path="services">
            <OuterServicesShow />
          </Tab>
          <Tab label="Address" path="addresses">
            <OutletAddress isShow resource="addresses" title="Address" />
          </Tab>
          <Tab label="Delivery Address" path="delivery-addresses">
            <OutletAddress
              isShow
              resource="delivery-addresses"
              title="Delivery Address"
              type="delivery"
            />
          </Tab>
          <Tab label="Contact" path="contacts">
            <OutletContact isShow />
          </Tab>
          <Tab label="Experts" path="experts">
            <OutletExperts isShow />
          </Tab>
          <Tab label="Contracts" path="contracts">
            <OutletContracts isShow />
          </Tab>
        </TabbedShowLayout>
      </Show>
    );
  }

  return <></>;
};
