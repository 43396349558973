// core imports
import { TextInput, SimpleForm } from 'react-admin';

// mui styles
import { Box } from '@mui/material';

export const ExpertTypeForm = () => (
  <SimpleForm disableInvalidFormNotification>
    <Box flex="1" mt={-1}>
      <Box display="flex" width={430}>
        <TextInput source="type" fullWidth required />
      </Box>
    </Box>
  </SimpleForm>
);
