const {
  REACT_APP_GIGYA_URL_AUTH,
  REACT_APP_GIGYA_API_KEY,
  REACT_APP_API_GATEWAY_BASE_URL,
} = process.env;

export const ENDPOINTS = {
  API_BASE_URL: `${REACT_APP_API_GATEWAY_BASE_URL}`,
  AUTH: {
    AUTHORIZE: `${REACT_APP_GIGYA_URL_AUTH}${REACT_APP_GIGYA_API_KEY}/authorize`,
    TOKEN: `${REACT_APP_API_GATEWAY_BASE_URL}/token`,
    LOGOUT: `${REACT_APP_API_GATEWAY_BASE_URL}/logout`,
    USER_INFO: `${REACT_APP_API_GATEWAY_BASE_URL}/user-info`,
    ACCOUNT: `${REACT_APP_API_GATEWAY_BASE_URL}/account-info`,
    TOKEN_INTROSPECT: `${REACT_APP_API_GATEWAY_BASE_URL}/introspect-token`,
    ROLE: `${REACT_APP_API_GATEWAY_BASE_URL}/role`,
  },
};
