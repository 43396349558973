// core imports
import { ComponentType, ReactElement } from 'react';
import { Admin as RaAdmin, Resource, ResourceProps } from 'react-admin';
import { QueryClient } from 'react-query';

// resources
import merchant from 'resources/merchant';
import outlet from 'resources/outlet';
import address from 'resources/address';
import marketArea from 'resources/market-area';
import expert from 'resources/expert';
import contact from 'resources/contact';
import openingHour from 'resources/opening-hour';
import expertType from 'resources/expert-type';
import expertPosition from 'resources/expert-position';
import service from 'resources/service';
import offeredService from 'resources/offered-service';
import contract from 'resources/contract';
import gssnIssue from 'resources/gssn-issue';

// dashboard
import { DashboardWrapper } from 'components/dashboard/DashboardWrapper';

// config
import { AuthorizedRoles } from 'utils/auth.config';

// dataProvider
import { DataProvider } from 'dataProvider/DataProvider';

// authProvider
import { authProvider } from './auth/authProvider';

// component
import Layout from './components/layout/Layout';

// theme
import { theme, darkTheme } from './styles/theme';

/** Base resources accessible to all roles */
const BASE_RESOURCES: ResourceProps[] = [
  merchant,
  outlet,
  address.address,
  address.deliveryAddress,
  marketArea,
  expert,
  contact,
  openingHour,
];

/** Extended resources accessible to ADMIN and READ roles */
const EXTENDED_RESOURCES: ResourceProps[] = [
  expertType,
  expertPosition,
  service,
  offeredService,
  contract,
  gssnIssue,
];

/**
 * Filter resources based on environment and role
 *
 * @param {ResourceProps[]} resources the resources to filter
 * @param {string} role the role of the user
 * @returns {ResourceProps[]} the filtered resources
 */
const filterResources = (
  resources: ResourceProps[],
  role: string
): ResourceProps[] => {
  const env = process.env.REACT_APP_ENV;

  return resources.filter((resource) => {
    const disabledIn = resource?.options?.disabledIn;
    const requiredRoles = resource?.options?.requiredRoles;

    if (disabledIn && disabledIn.includes(env)) {
      return false;
    }

    if (requiredRoles && !requiredRoles.includes(role)) {
      return false;
    }

    return true;
  });
};

export const Admin = (): JSX.Element => {
  // Stale time set to 5 minutes for queries
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <RaAdmin
      requireAuth
      dashboard={DashboardWrapper}
      dataProvider={DataProvider}
      authProvider={authProvider}
      queryClient={queryClient}
      theme={theme}
      darkTheme={darkTheme}
      layout={Layout}
      disableTelemetry
    >
      {(role) => {
        const extendedRole =
          role === AuthorizedRoles.ADMIN ||
          role === AuthorizedRoles.MARKET_ADMIN ||
          role === AuthorizedRoles.READ;

        const baseResources = filterResources(BASE_RESOURCES, role);
        const extendedResources = filterResources(EXTENDED_RESOURCES, role);

        const canModify = (
          resource: ComponentType<any> | ReactElement | undefined
        ) => (role === AuthorizedRoles.READ ? undefined : resource);

        return (
          <>
            {role && (
              <>
                {baseResources.map((resource) => (
                  <Resource
                    {...resource}
                    create={canModify(resource.create)}
                    edit={canModify(resource.edit)}
                  />
                ))}
              </>
            )}
            {extendedRole && (
              <>
                {extendedResources.map((resource) => (
                  <Resource
                    {...resource}
                    create={canModify(resource.create)}
                    edit={canModify(resource.edit)}
                  />
                ))}
              </>
            )}
          </>
        );
      }}
    </RaAdmin>
  );
};
