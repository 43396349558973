import { Datagrid } from 'components/data-grid/DataGrid';
import { List } from 'components/list/List';
import { ListActions } from 'components/list/ListActions';
import { TextField } from 'components/text-field/TextField';
import {
  DateField,
  ReferenceField,
  SimpleList,
  downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useSmallScreen } from 'utils/helper';
import { DataProvider } from 'dataProvider/DataProvider';
import { ContractFilters } from './ContractFilters';

const ContractExporter = async (contracts) => {
  try {
    const { data: outlets } = await DataProvider.getList('outlets', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: {},
    });

    const contractsWithOutlet = contracts.map((contract) => {
      const outletField = outlets.find(
        (outlet) => outlet.id === contract.outletId
      );

      const { contractName, contractType, id, ...rest } = contract;

      return {
        id,
        contractType,
        subjectMatter: contractName,
        outletName: outletField?.name,
        ...rest,
      };
    });

    const csv = await jsonExport(contractsWithOutlet);
    downloadCSV(csv, 'contracts');
  } catch (error) {
    console.debug('Something went wrong when trying to export to csv', error);
  }
};

export const ContractList = () => {
  const isSmallScreen = useSmallScreen();

  return (
    <List
      title="Contracts"
      actions={<ListActions showExport showFilter />}
      filters={ContractFilters}
      exporter={ContractExporter}
    >
      {isSmallScreen ? (
        <SimpleList
          primaryText={(record) => record.contractName}
          secondaryText={(record) => record.contractType}
          tertiaryText={(record) => record.comment}
          linkType="show"
        />
      ) : (
        <Datagrid>
          <ReferenceField
            source="outletId"
            reference="outlets"
            label="Outlet"
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="contractType" />
          <TextField source="contractName" label="Subject matter" />
          <DateField source="startDate" emptyText="-" />
          <DateField source="endDate" emptyText="-" />
          <TextField source="comment" emptyText="-" />
        </Datagrid>
      )}
    </List>
  );
};
