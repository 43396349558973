import { useRecordContext } from 'react-admin';

import { EditButton } from 'components/buttons/EditButton';
import { EditWithRedirection } from 'resources/types/resources.types';

export const EditContractButton = ({
  recordContext = {},
  outletId,
  merchantId,
}: EditWithRedirection) => {
  const record = useRecordContext(recordContext);

  if (!record) {
    return null;
  }

  let editRedirect = `/contracts/${record?.id}/?outlet_id=${outletId}`;

  if (merchantId) {
    editRedirect = `${editRedirect}&merchant_id=${merchantId}`;
  }

  return <EditButton navigateTo={editRedirect} />;
};
