// core imports
import { useState } from 'react';
import { TabbedForm, TextInput, FormTab, NumberInput, Form } from 'react-admin';
import { Box } from '@mui/material';

// hook import
import { useSearchParams } from 'react-router-dom';

// local component
import { Create } from 'components/create/Create';
import { Toolbar } from 'components/toolbar/Toolbar';

// local import
import { mapAddressDefaultValues } from 'utils/helper';
import { requiredValidator } from 'utils/validators';
import { AddressValidator } from 'components/address-validator/AddressValidator';
import { LoqateAddress } from '../types/loqate';
import { ReferenceOutlet } from '../outlet/ReferenceOutlet';

interface FormProps {
  getCompleteAddress: (address: LoqateAddress) => void;
  fullAddressData: LoqateAddress | undefined;
  redirectTo: string;
  addressTransform: (address: LoqateAddress) => LoqateAddress;
  selectedOutletId: string | undefined;
}

const DeliveryForm = ({
  getCompleteAddress,
  fullAddressData,
  addressTransform,
  selectedOutletId,
  redirectTo,
}: FormProps) => (
  <>
    <Box>
      <AddressValidator onSelect={getCompleteAddress} />
    </Box>
    {fullAddressData && (
      <Create
        redirect={redirectTo}
        resourceName="Address"
        transform={addressTransform}
      >
        <Form
          disableInvalidFormNotification
          defaultValues={mapAddressDefaultValues(fullAddressData)}
        >
          <Box
            sx={{ display: 'flex' }}
            flexDirection="column"
            alignItems="flex-start"
            margin={2}
          >
            <TextInput source="city" validate={requiredValidator} />
            <TextInput source="street" validate={requiredValidator} />
            <TextInput source="postalCode" validate={requiredValidator} />
            <TextInput source="countryIso2" validate={requiredValidator} />
            <ReferenceOutlet
              source="id"
              defaultValue={selectedOutletId}
              isDisabled={!!selectedOutletId}
            />
          </Box>
          <Toolbar />
        </Form>
      </Create>
    )}
  </>
);

const AddressForm = ({
  getCompleteAddress,
  addressTransform,
  fullAddressData,
  redirectTo,
  selectedOutletId,
}: FormProps) => (
  <>
    <Box>
      <AddressValidator onSelect={getCompleteAddress} />
    </Box>
    {fullAddressData && (
      <Create
        redirect={redirectTo}
        resourceName="Address"
        transform={addressTransform}
      >
        <TabbedForm
          toolbar={<Toolbar />}
          disableInvalidFormNotification
          defaultValues={mapAddressDefaultValues(fullAddressData)}
        >
          <FormTab label="summary">
            <TextInput source="street" validate={requiredValidator} />
            <TextInput source="city" validate={requiredValidator} disabled />
            <TextInput source="line1" />
            <TextInput source="district" disabled />
            <NumberInput source="buildingNumber" />
            <TextInput source="province" disabled />
            <TextInput
              source="postalCode"
              validate={requiredValidator}
              disabled
            />
            <ReferenceOutlet
              source="id"
              defaultValue={selectedOutletId}
              isDisabled={!!selectedOutletId}
            />
          </FormTab>
          <FormTab label="Language">
            <TextInput source="language" />
            <TextInput source="languageAlternatives" />
          </FormTab>
          <FormTab label="Building">
            <TextInput source="subBuilding" />
            <NumberInput source="buildingNumber" />
            <TextInput source="buildingName" />
            <TextInput source="block" />
            <TextInput source="company" />
            <TextInput source="department" />
          </FormTab>
          <FormTab label="Street">
            <TextInput source="secondaryStreet" />
            <TextInput source="street" validate={requiredValidator} />
          </FormTab>
          <FormTab label="Locality">
            <TextInput source="neighbourhood" />
            <TextInput source="district" disabled />
            <TextInput source="city" validate={requiredValidator} disabled />
            <TextInput source="line1" />
            <TextInput source="line2" />
            <TextInput source="line3" />
            <TextInput source="line4" />
            <TextInput source="line5" />
          </FormTab>
          <FormTab label="Admin Area">
            <TextInput source="adminAreaName" disabled />
            <TextInput source="adminAreaCode" disabled />
          </FormTab>
          <FormTab label="Province">
            <TextInput source="province" disabled />
            <TextInput source="provinceName" disabled />
            <TextInput source="provinceCode" disabled />
          </FormTab>
          <FormTab label="Country">
            <TextInput
              source="countryName"
              validate={requiredValidator}
              disabled
            />
            <TextInput source="countryIso2" disabled />
            <TextInput source="countryIso3" disabled />
            <TextInput source="countryIsoNumber" disabled />
            <TextInput
              source="postalCode"
              validate={requiredValidator}
              disabled
            />
            <TextInput source="poBoxNumber" disabled />
          </FormTab>
          <FormTab label="Geo Codes">
            <TextInput source="latitude" />
            <TextInput source="longitude" />
          </FormTab>
        </TabbedForm>
      </Create>
    )}
  </>
);

interface AddressCreateProps {
  resource: string;
  type?: 'delivery' | 'address';
}

export const AddressCreate = ({ resource, type }: AddressCreateProps) => {
  const [searchParams] = useSearchParams();

  const selectedOutletId = searchParams.get('outlet_id') as string | undefined;
  const [fullAddressData, setFullAddressData] = useState<
    LoqateAddress | undefined
  >();

  const getCompleteAddress = (address: LoqateAddress) => {
    if (address) {
      setFullAddressData(address);
    }
  };

  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  let redirectTo = 'list';

  if (selectedOutletId) {
    redirectTo = `/outlets/${selectedOutletId}/${resource}`;

    if (selectedMerchantId) {
      redirectTo = `${redirectTo}?merchant_id=${selectedMerchantId}`;
    }
  }

  /**
   * Transform the data to include loqateId and domesticId
   */
  const addressTransform = (address: LoqateAddress) => ({
    ...address,
    loqateId: address?.Id,
    domesticId: address?.DomesticId,
  });

  const props = {
    redirectTo,
    fullAddressData,
    selectedOutletId,
    addressTransform,
    getCompleteAddress,
  };

  return type === 'delivery' ? (
    <DeliveryForm {...props} />
  ) : (
    <AddressForm {...props} />
  );
};

AddressCreate.defaultProps = {
  type: 'address',
};
