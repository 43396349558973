import { useSearchParams } from 'react-router-dom';
import { Edit } from 'components/edit/Edit';
import { ContractForm } from './ContractForm';

export const ContractEdit = () => {
  const [searchParams] = useSearchParams();
  const selectedOutletId = searchParams.get('outlet_id') as string | undefined;

  return (
    <Edit resourceName="Contract">
      <ContractForm selectedOutletId={selectedOutletId} isEdit />
    </Edit>
  );
};
