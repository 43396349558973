import { useRecordContext } from 'react-admin';

export const OutletTitle = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  const defaultTitle = `Outlet ${record?.outletId}`;
  const title = record.name ? `${defaultTitle} - ${record.name}` : defaultTitle;

  return <span>{title}</span>;
};
