// core imports
import {
  Show,
  SimpleShowLayout,
  RaRecord,
  FunctionField,
  TextField,
  ReferenceField,
} from 'react-admin';

// mui import
import { Box } from '@mui/material';

// local imports
import { FromFunctionField, UntilFunctionField } from './OpeningHoursList';
import { OpeningHoursTitle } from './OpeningHoursTitle';

// config
import { getEuropeTimeZones } from './config';
import { DEFAULT_TIMEZONE } from '../config/resources.config';

const europeTzList = getEuropeTimeZones();

const OpeningHoursForm = () => (
  <Box sx={{ display: 'flex' }}>
    <SimpleShowLayout>
      <ReferenceField source="id" reference="opening-hours" label="Service">
        <TextField source="offeredService.service.name" />
      </ReferenceField>
      <ReferenceField source="id" reference="opening-hours" label="Outlet">
        <TextField source="offeredService.outlet.name" />
      </ReferenceField>
      <ReferenceField source="id" reference="opening-hours" label="Weekday">
        <TextField source="weekDay" />
      </ReferenceField>
    </SimpleShowLayout>
    <SimpleShowLayout>
      <FunctionField
        label="Timezone"
        render={(record: RaRecord) => {
          const selectedTimeZoneOffset = record?.from.substring(
            record?.from.indexOf('+')
          );

          const tzValue = selectedTimeZoneOffset
            ? europeTzList?.find(
                (timezone) => timezone?.id === selectedTimeZoneOffset
              )?.name
            : DEFAULT_TIMEZONE;

          return tzValue ?? '-';
        }}
      />
      <FromFunctionField source="from" />
      <UntilFunctionField source="until" />
    </SimpleShowLayout>
  </Box>
);
export const OpeningHoursShow = () => (
  <Show title={<OpeningHoursTitle />}>
    <OpeningHoursForm />
  </Show>
);
