// core imports
import {
  useEditController,
  useGetOne,
  SimpleShowLayout,
  Button,
  RaRecord,
  FunctionField,
} from 'react-admin';

// router import
import { Link, useSearchParams } from 'react-router-dom';

// material-ui
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

// component
import { TextField } from 'components/text-field/TextField';

// utils
import { formatPhoneNumber } from 'utils/helper';

// style
import { WrapperComponent, RedirectButton } from './Outlet.style';

import { Error } from '../types/resources.types';

export const OutletContact = ({ isShow = false }: { isShow?: boolean }) => {
  const controllerProps = useEditController();
  const outletId = controllerProps?.record?.id;
  const isGssnRecord = !!controllerProps?.record?.extOutletId;

  const [searchParams] = useSearchParams();
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  const {
    data,
    isLoading,
    error,
  }: {
    data: RaRecord | undefined;
    isLoading: boolean;
    error: Error | unknown;
  } = useGetOne(
    'contacts',
    {
      id: outletId,
    },
    { retry: false }
  );
  let errorStatus;

  if (error) {
    errorStatus = JSON.parse(JSON.stringify(error))?.status;
  }

  if (isLoading) {
    return <div>loading</div>;
  }

  if (errorStatus === 404) {
    if (isShow) return <div>No contact found</div>;

    let createRedirect = `/contacts/create?outlet_id=${outletId}`;

    if (selectedMerchantId) {
      createRedirect = `${createRedirect}&merchant_id=${selectedMerchantId}`;
    }

    return (
      <>
        <Box sx={{ marginBottom: 5 }}> No contact found </Box>
        <Button
          variant="contained"
          size="medium"
          component={Link}
          to={createRedirect}
          label="Add contact"
          title="Add Contact"
        >
          <AddIcon />
        </Button>
      </>
    );
  }

  let editRedirect = `/contacts/${data?.id}?outlet_id=${data?.id}`;

  if (selectedMerchantId) {
    editRedirect = `${editRedirect}&merchant_id=${selectedMerchantId}`;
  }

  return (
    <WrapperComponent>
      <SimpleShowLayout record={data}>
        <FunctionField
          label="Fax"
          render={(record: RaRecord) =>
            record?.fax ? formatPhoneNumber(record.fax) : '-'
          }
        />
        <FunctionField
          label="Phone"
          render={(record: RaRecord) =>
            record?.phone ? formatPhoneNumber(record.phone) : '-'
          }
        />
        <TextField source="email" />
      </SimpleShowLayout>

      {!isShow && (
        <RedirectButton
          variant="contained"
          disabled={isGssnRecord}
          size="medium"
          component={Link}
          to={editRedirect}
          label="Edit contact"
          title="Edit Contact"
        >
          <EditIcon />
        </RedirectButton>
      )}
    </WrapperComponent>
  );
};

OutletContact.defaultProps = {
  isShow: false,
};
