// core imports
import {
  useEditController,
  ReferenceManyField,
  Button,
  BooleanField,
  useRecordContext,
} from 'react-admin';
import { Link } from 'react-router-dom';

// mui-styles
import AddIcon from '@mui/icons-material/Add';

// component
import { Datagrid } from 'components/data-grid/DataGrid';
import { EditButton } from 'components/buttons/EditButton';
import { Pagination } from 'components/list/Pagination';
import { TextField } from 'components/text-field/TextField';

// config
import { notApplicable } from 'resources/config/resources.config';

// types
import { RecordContext } from '../types/resources.types';

const AddOutlet = ({ merchantId }: { merchantId: number }) => (
  <Button
    variant="contained"
    size="medium"
    component={Link}
    to={`/outlets/create/?merchant_id=${merchantId}`}
    label="Add Outlet"
    title="Add Outlet"
    sx={{
      marginTop: 5,
    }}
  >
    <AddIcon />
  </Button>
);

const EmptyMessage = () => <div>No outlets found</div>;

const OutletEdit = ({ recordContext = {} }: RecordContext) => {
  const record = useRecordContext(recordContext);

  if (!record) {
    return null;
  }

  return (
    <EditButton
      navigateTo={`/outlets/${record?.id}/?merchant_id=${record?.merchantId}`}
    />
  );
};

export const MerchantOutlets = ({ isEdit }: { isEdit?: boolean }) => {
  const controllerProps = useEditController();
  const createOutlet = <AddOutlet merchantId={controllerProps?.record?.id} />;

  return (
    <>
      <ReferenceManyField
        pagination={<Pagination />}
        reference="outlets"
        label="Outlets"
        target="merchantId"
      >
        <Datagrid
          empty={<EmptyMessage />}
          rowClick={
            isEdit
              ? (id, resource, record) =>
                  `/outlets/${record?.id}/?merchant_id=${record?.merchantId}`
              : (id, resource, record) => `/outlets/${record?.id}/show`
          }
          bulkActionButtons={isEdit}
        >
          <TextField
            source="extOutletId"
            label="External Outlet Id"
            emptyText={notApplicable}
          />
          <TextField source="outletId" label="Outlet Id" />
          <TextField source="name" label="Outlet" />
          <TextField source="cofico" />
          <TextField source="brandCode" />
          <BooleanField source="isMainOutlet" label="Is Main Outlet" />
          <BooleanField source="isActive" label="Is Active" />
          <BooleanField source="isApplicant" label="Is Applicant" />
          <TextField source="vat" />
          {isEdit && <OutletEdit />}
        </Datagrid>
      </ReferenceManyField>
      {isEdit && createOutlet}
    </>
  );
};

MerchantOutlets.defaultProps = {
  isEdit: false,
};
