import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  RaRecord,
} from 'react-admin';

const optionRenderer = (choice: RaRecord) => {
  if (choice?.merchantName) {
    return `${choice?.companyId} - ${choice?.merchantName}`;
  }

  return `${choice?.companyId}`;
};

export const OutletFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <TextInput source="name" label="Outlet" />,
  <ReferenceInput label="Partner" reference="merchants" source="merchantId">
    <AutocompleteInput optionText={optionRenderer} label="Partner" />
  </ReferenceInput>,
  <TextInput source="cofico" />,
  <TextInput source="brandCode" />,
  <BooleanInput source="isMainOutlet" label="Is Main Outlet" />,
  <BooleanInput source="isActive" label="Is Active" />,
  <BooleanInput source="isApplicant" label="Is Applicant" />,
  <TextInput source="address.countryName" label="Country" />,
  <TextInput source="address.city" label="City" />,
  <TextInput source="vat" />,
];
