import { createTheme, Theme } from '@mui/material/styles';
import { defaultDarkTheme, RaThemeOptions } from 'react-admin';
import SmartSansRegular from '../assets/fonts/FORsmartNext-Regular.woff2';

// it could be your App.tsx file or theme file that is included in your tsconfig.json
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {
    custom: boolean;
  }
}

const baseStyles: RaThemeOptions = {
  breakpoints: {
    values: {
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
  typography: {
    fontFamily: 'FORsmartNext-Regular',
    fontSize: 16,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'FORsmartNext-Regular';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local("FOR smart Sans"), 
          local('FORsmartNext-Regular.woff2'), url(${SmartSansRegular}) format('opentype');          
        }
      `,
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + $track': {
              opacity: 1,
              border: 'none',
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: '1px solid #bdbdbd',
          backgroundColor: '#fafafa',
          opacity: 1,
          transition:
            'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          minHeight: 96,
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
};

const lightOverride: RaThemeOptions = {
  components: {
    ...baseStyles.components,
    MuiList: {
      defaultProps: {
        dense: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'whitesmoke',
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          '& .MuiList-root': {
            backgroundColor: '#FFFFFF',
          },
        },
      },
    },
  },
};

const darkOverride: RaThemeOptions = {
  components: {
    ...baseStyles.components,
    MuiList: {
      defaultProps: {
        dense: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: '#222222',
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          '& .MuiList-root': {
            backgroundColor: '#313131',
          },
        },
      },
    },
  },
};

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#141413',
    },
    secondary: {
      main: '#e0e0e0',
    },
    success: {
      main: '#ace6b7',
    },
    error: {
      main: '#ea9c98',
    },
    warning: {
      main: '#f7bf31',
    },
    info: {
      main: '#7dcfe3',
    },
  },
  ...baseStyles,
  ...lightOverride,
});

export const darkTheme = createTheme({
  ...defaultDarkTheme,
  ...baseStyles,
  ...darkOverride,
});
