import { Button } from 'react-admin';
import { DataProvider } from 'dataProvider/DataProvider';
import { Buffer } from 'buffer';
import DownloadIcon from '@mui/icons-material/Download';

export const DownloadButton = () => {
  const handleDownload = async () => {
    const response = await DataProvider.fetchXlsxFile('outlet-details');
    const binaryData = Buffer.from(response.body, 'base64');
    const blob = new Blob([binaryData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = response.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button label="Download details" onClick={handleDownload}>
      <DownloadIcon />
    </Button>
  );
};
