export const gssnIssueStyles = {
  errorMessage: {
    display: 'block',
    width: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  datagrid: {
    layout: { tableLayout: 'fixed' },
    marginTop: '60px',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  emptyLight: {
    color: '#9b9b9b',
  },
  emptyDark: {
    color: '#dadada',
  },
  icon: {
    width: '9em',
    height: '9em',
  },
};
