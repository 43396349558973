import { RESOURCES } from 'resources/config/resources.config';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { MerchantList } from './MerchantList';
import { MerchantEdit } from './MerchantEdit';
import { MerchantCreate } from './MerchantCreate';
import { MerchantShow } from './MerchantShow';

export default {
  key: RESOURCES.merchants,
  name: RESOURCES.merchants,
  list: MerchantList,
  edit: MerchantEdit,
  create: MerchantCreate,
  show: MerchantShow,
  icon: ApartmentIcon,
  options: { label: 'Partners' },
};
