import { useSearchParams } from 'react-router-dom';
import { useEditContext, useGetOne } from 'react-admin';

import { Edit } from 'components/edit/Edit';
import { ContactForm } from './ContactForm';

const ContactEditForm = ({
  selectedOutletId,
}: {
  selectedOutletId: string | undefined;
}) => {
  const { record } = useEditContext();
  const { data } = useGetOne('outlets', { id: record?.id });
  const isGssn = !!data?.extOutletId;

  return (
    <ContactForm
      isGssn={isGssn}
      isDisabled
      selectedOutletId={selectedOutletId}
    />
  );
};

export const ContactEdit = () => {
  const [searchParams] = useSearchParams();
  const selectedOutletId = searchParams.get('outlet_id') as string | undefined;
  const selectedMerchantId = searchParams.get('merchant_id') as
    | string
    | undefined;

  let redirectTo = 'list';

  if (selectedOutletId) {
    redirectTo = `/outlets/${selectedOutletId}/contacts`;

    if (selectedMerchantId) {
      redirectTo = `${redirectTo}?merchant_id=${selectedMerchantId}`;
    }
  }

  return (
    <Edit redirect={redirectTo} resourceName="Contact">
      <ContactEditForm selectedOutletId={selectedOutletId} />
    </Edit>
  );
};
