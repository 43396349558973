import { Box } from '@mui/material';
import { DateInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { CONTRACT_TYPES } from 'resources/config/resources.config';
import { ReferenceOutlet } from 'resources/outlet/ReferenceOutlet';
import { endDateValidator, requiredValidator } from 'utils/validators';

export const ContractForm = ({
  isEdit = false,
  selectedOutletId,
}: {
  isEdit?: boolean;
  selectedOutletId?: string | undefined;
}) => {
  let outletFilters = {};

  if (selectedOutletId) {
    outletFilters = { id: selectedOutletId };
  }

  return (
    <SimpleForm
      mode="onChange"
      reValidateMode="onChange"
      disableInvalidFormNotification
    >
      <Box flex="1" mt={-1}>
        <Box display="flex">
          <ReferenceOutlet
            defaultValue={selectedOutletId}
            filter={outletFilters}
            isDisabled={!!selectedOutletId || isEdit}
          />
        </Box>
        <Box display="flex">
          <SelectInput
            source="contractType"
            validate={requiredValidator}
            choices={CONTRACT_TYPES}
          />
        </Box>
        <Box display="flex">
          <TextInput
            label="Subject matter"
            source="contractName"
            fullWidth
            validate={requiredValidator}
          />
        </Box>
        <Box display="flex">
          <DateInput source="startDate" validate={requiredValidator} />
        </Box>
        <Box display="flex">
          <DateInput source="endDate" fullWidth validate={endDateValidator} />
        </Box>
        <Box display="flex">
          <TextInput source="comment" fullWidth />
        </Box>
      </Box>
    </SimpleForm>
  );
};

ContractForm.defaultProps = {
  isEdit: false,
  selectedOutletId: '',
};
