// core imports
import {
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';

// mui imports
import { Box } from '@mui/material';

// config
import { COMMUNICATION_TYPES } from 'resources/config/resources.config';

// local import
import {
  expertNameRegexValidator,
  requiredValidator,
  phoneValidator,
} from 'utils/validators';
import { ReferenceOutlet } from '../outlet/ReferenceOutlet';

const Spacer = () => <Box width={20} component="span" />;

export const ExpertForm = ({
  isEdit = false,
  selectedOutletId,
}: {
  isEdit?: boolean;
  selectedOutletId?: string;
}) => {
  let outletFilters = {};

  if (selectedOutletId) {
    outletFilters = { id: selectedOutletId };
  }

  return (
    <SimpleForm
      mode="onChange"
      reValidateMode="onChange"
      disableInvalidFormNotification
    >
      <Box flex="1" mt={-1}>
        <Box display="flex">
          <TextInput
            type="email"
            source="email"
            disabled={isEdit}
            fullWidth
            required
          />
        </Box>

        <Box display="flex">
          <TextInput
            source="phone"
            fullWidth
            required
            validate={[phoneValidator]}
            helperText="Eg: +49xxxx"
          />
        </Box>

        <Box display="flex" width={430}>
          <TextInput
            source="firstName"
            fullWidth
            validate={[
              requiredValidator,
              expertNameRegexValidator('first name'),
            ]}
          />
          <Spacer />
          <TextInput
            source="lastName"
            fullWidth
            validate={[
              requiredValidator,
              expertNameRegexValidator('last name'),
            ]}
          />
        </Box>
        <Box display="flex" width={530}>
          <ReferenceInput
            label="Expert Type"
            reference="expert-types"
            source="typeId"
          >
            <SelectInput optionText="type" required />
          </ReferenceInput>
          <Spacer />
          <ReferenceInput
            label="Expert Position"
            reference="expert-positions"
            source="positionId"
          >
            <SelectInput optionText="position" />
          </ReferenceInput>
        </Box>
        <BooleanInput source="isActive" />
      </Box>
      <Box>
        <ReferenceOutlet
          defaultValue={selectedOutletId}
          filter={outletFilters}
          isDisabled={!!selectedOutletId || isEdit}
        />
      </Box>
      {isEdit && (
        <Box display="flex">
          <TextInput
            source="gigyaId"
            label="Gigya Id"
            // required
            // disabled
            sx={{ width: 300 }}
          />
        </Box>
      )}
      <Box display="flex">
        <SelectInput
          source="prefCommLang"
          label="Preferred Communication"
          choices={COMMUNICATION_TYPES}
          required
          disabled={isEdit}
          sx={{ width: 260 }}
        />
      </Box>
    </SimpleForm>
  );
};

ExpertForm.defaultProps = {
  isEdit: false,
  selectedOutletId: '',
};
