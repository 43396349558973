// core import
import { useRecordContext } from 'react-admin';

// component
import { Edit } from 'components/edit/Edit';

// local import
import { ExpertTypeForm } from './ExpertTypeForm';

const ExpertTypeTitle = () => {
  const record = useRecordContext();

  return !record ? null : (
    <span>
      Expert Type {record?.id} - {record?.type}
    </span>
  );
};

export const ExpertTypeEdit = () => (
  <Edit title={<ExpertTypeTitle />} resourceName="Expert-type">
    <ExpertTypeForm />
  </Edit>
);
