import { DownloadButton } from 'components/buttons/DownloadButton';
import {
  TopToolbar,
  SelectColumnsButton,
  CreateButton,
  FilterButton,
  ExportButton,
  usePermissions,
} from 'react-admin';
import { AuthorizedRoles } from 'utils/auth.config';

// Action buttons for the list view. Default button always shown is: SelectColumnsButton.
export const ListActions = ({
  showExport,
  showFilter,
  showXlsxExport,
}: {
  showExport?: boolean;
  showFilter?: boolean;
  showXlsxExport?: boolean;
}) => {
  const { permissions } = usePermissions();
  const showCreate = permissions !== AuthorizedRoles.READ;

  return (
    <TopToolbar>
      <SelectColumnsButton />
      {showFilter && <FilterButton />}
      {showCreate && <CreateButton />}
      {showExport && <ExportButton />}
      {showXlsxExport && <DownloadButton />}
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  showExport: false,
  showFilter: false,
  showXlsxExport: false,
};
