import { Button, Link } from 'react-admin';

const OpeningHoursAdd = ({ selectedOutletId, selectedMerchantId }) => {
  let createRedirect = `/opening-hours/create?outlet_id=${selectedOutletId}`;
  if (selectedMerchantId) {
    createRedirect = `${createRedirect}&merchant_id=${selectedMerchantId}`;
  }
  return (
    <Button
      size="medium"
      variant="contained"
      component={Link}
      to={createRedirect}
      label="Add an opening hour"
    />
  );
};
export default OpeningHoursAdd;
