// core imports
import { useState, useEffect } from 'react';
import {
  SimpleForm,
  AutocompleteInput,
  useDataProvider,
  FormDataConsumer,
} from 'react-admin';

// mui-style import
import { Box } from '@mui/material';

// component import
import { Create } from 'components/create/Create';

// local import
import { requiredValidator } from 'utils/validators';
import { ReferenceOutlet } from '../outlet/ReferenceOutlet';

// types import
import { ServiceType } from '../types/resources.types';

const AvailableServices = ({
  selectedOutletId,
  allServices,
}: {
  selectedOutletId: number;
  allServices: ServiceType[];
}) => {
  const dataProvider = useDataProvider();
  const [availableServices, setAvailableServices] = useState<
    ServiceType[] | undefined
  >([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (selectedOutletId) {
      dataProvider
        .getList('offered-services', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: { outletId: selectedOutletId },
        })
        .then(({ data }) => {
          if (data.length !== allServices?.length) {
            // filter the services which are not offered by the selected outlet
            const outletServices = allServices?.filter(
              (service: { id: number }) =>
                !data.some(
                  (offeredService) => service.id === offeredService.serviceId
                )
            );
            setAvailableServices(outletServices);
          }
          setIsLoaded(true);
        })
        .catch(() => {
          setIsLoaded(true);
        });
    }
  }, [selectedOutletId, dataProvider, allServices]);

  if (isLoaded && availableServices?.length === 0) {
    return <div>All services are attached to the selected outlet</div>;
  }
  return (
    <AutocompleteInput
      source="serviceId"
      label="Service"
      choices={availableServices}
      optionText="name"
      validate={requiredValidator}
      disabled={!isLoaded}
    />
  );
};

export const OfferedServiceCreate = () => {
  const dataProvider = useDataProvider();
  const [services, setServices] = useState<ServiceType[]>([]);

  useEffect(() => {
    dataProvider
      .getList('services', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      })
      .then(({ data }) => setServices(data))
      .catch(() => {
        // @ToDo display error message
      });
  }, [dataProvider]);

  return (
    <Create resourceName="Offered-service">
      <SimpleForm>
        <Box flex="1" mt={-1}>
          <Box display="flex">
            <ReferenceOutlet />
          </Box>
          <FormDataConsumer>
            {({ formData }) => (
              <AvailableServices
                selectedOutletId={formData.outletId}
                allServices={services}
              />
            )}
          </FormDataConsumer>
        </Box>
      </SimpleForm>
    </Create>
  );
};
