import { RESOURCES } from 'resources/config/resources.config';
import ContactsIcon from '@mui/icons-material/Contacts';
import { ContactCreate } from './ContactCreate';
import { ContactEdit } from './ContactEdit';
import { ContactList } from './ContactList';
import { ContactShow } from './ContactShow';

export default {
  key: RESOURCES.contacts,
  name: RESOURCES.contacts,
  list: ContactList,
  create: ContactCreate,
  edit: ContactEdit,
  show: ContactShow,
  icon: ContactsIcon,
};
