import {
  FunctionField,
  RaRecord,
  ReferenceField,
  TextField,
  SimpleList,
} from 'react-admin';

import { MARKET_TYPES } from 'resources/config/resources.config';

import { List } from 'components/list/List';
import { Datagrid } from 'components/data-grid/DataGrid';
import { useSmallScreen } from 'utils/helper';

import { ListActions } from 'components/list/ListActions';
import { MarketAreaFilters } from './MarketAreaFilter';

export const MarketAreaList = () => {
  const isSmallScreen = useSmallScreen();
  return (
    <List
      filters={MarketAreaFilters}
      title="Market Areas"
      actions={<ListActions showExport showFilter />}
    >
      {isSmallScreen ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={
            <ReferenceField
              source="merchantId"
              reference="merchants"
              label="Partner Name"
              sortable={false}
            >
              <TextField source="merchantName" />
            </ReferenceField>
          }
          tertiaryText={(record) => record.market}
          linkType="show"
        />
      ) : (
        <Datagrid>
          <ReferenceField
            source="merchantId"
            reference="merchants"
            label="Company Id"
            sortable={false}
          >
            <TextField source="companyId" />
          </ReferenceField>
          <ReferenceField
            source="merchantId"
            reference="merchants"
            label="Partner Name"
            sortable={false}
          >
            <TextField source="merchantName" />
          </ReferenceField>
          <TextField source="name" />
          <FunctionField
            label="Market"
            source="market"
            render={(record: RaRecord) =>
              record?.market
                ? MARKET_TYPES.find((market) => market.id === record.market)
                    ?.name
                : '-'
            }
          />
        </Datagrid>
      )}
    </List>
  );
};
