import {
  TextInput,
  SelectInput,
  useEditContext,
  usePermissions,
} from 'react-admin';
import { Box } from '@mui/material';

// config
import { AuthorizedRoles } from 'utils/auth.config';
import { nameLengthValidators, requiredValidator } from 'utils/validators';
import { MERCHANT_TYPES } from '../config/resources.config';

export const MerchantForm = ({ isEdit = false }: { isEdit?: boolean }) => {
  const { permissions } = usePermissions(); // Get user's permissions

  const isAdmin = permissions === AuthorizedRoles.ADMIN;
  const { record, isLoading } = useEditContext();

  if (isLoading) {
    return null;
  }

  const isGssn = !!(isEdit && record?.extCompanyId);

  return (
    <>
      {isEdit && <TextInput source="companyId" label="Company Id" disabled />}

      <Box display="flex">
        <SelectInput
          source="type"
          choices={MERCHANT_TYPES}
          required
          disabled={isGssn}
        />
      </Box>
      <Box display="flex">
        <TextInput
          source="investorName"
          label="Investor Name"
          validate={nameLengthValidators}
          disabled={isGssn}
        />
      </Box>
      <TextInput
        source="merchantName"
        label="Partner Name"
        validate={[requiredValidator, ...nameLengthValidators]}
        disabled={isGssn}
      />
      <TextInput
        source="vat"
        format={(vat) => vat ?? ''}
        disabled={!isAdmin} // Disable the input field if the user is not an admin or is a network manager
        validate={requiredValidator}
      />
    </>
  );
};

MerchantForm.defaultProps = { isEdit: false };
