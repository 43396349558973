// core import
import { useRecordContext } from 'react-admin';

/**
 * ExpertTitle Component
 * @returns <ExpertTitle> ReactNode
 */
export const ExpertTitle = () => {
  const record = useRecordContext();

  return !record ? null : (
    <span>
      Expert {record?.expertId} - {record?.firstName}
    </span>
  );
};
